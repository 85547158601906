import { useRef, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, WebpFallback } from '../../../components';
import { QuizStep7_1, QuizStep7_1Png } from '../../../utils/images';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep7 = () => {
  const location = useLocation();

  const quizStep7 = useRef();

  useLayoutEffect(() => {
    // Quiz Step 6
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      const pieImg = document.querySelectorAll('[class*="quiz__question"][class*="question--7"] [class*="pic-pie"]');

      tl.from('[class*="quiz__question"][class*="question--7"] h1', {
          y: '50vh',
          opacity: 0,
          ease: 'power4',
          duration: 3
        })
        .fromTo(pieImg[2], {
          y: '-20vh',
          opacity: 0,
        }, {
          y: '70vh',
          opacity: 1,
          duration: 2
        }, 0.2)
        .to(pieImg[2], {
          opacity: 0,
          duration: 2
        }, '>-1.5')
        .fromTo(pieImg[5], {
          y: '-40vh',
          opacity: 0,
        }, {
          y: '70vh',
          opacity: 1,
          duration: 5
        }, 0.7)
        .to(pieImg[5], {
          opacity: 0,
          duration: 5
        }, '>-4')
        .fromTo(pieImg[6], {
          y: '-50vh',
          opacity: 0,
        }, {
          y: '70vh',
          opacity: 1,
          duration: 5
        }, 1)
        .to(pieImg[6], {
          opacity: 0,
          duration: 5
        }, '>-1.5')
        .fromTo(pieImg[3], {
          y: '-30vh',
          opacity: 0,
        }, {
          y: '50vh',
          opacity: 1,
          duration: 6
        }, 1.5)
        .to(pieImg[3], {
          opacity: 0,
          duration: 6
        }, '>-5')
        .fromTo(pieImg[0], {
          y: '-15vh',
          opacity: 0,
        }, {
          y: '90vh',
          opacity: 1,
          duration: 7
        }, 2)
        .to(pieImg[0], {
          opacity: 0,
          duration: 7
        }, '>-5')
        .fromTo(pieImg[7], {
          y: '-90vh',
          opacity: 0,
        }, {
          y: '30vh',
          opacity: 1,
          duration: 8
        }, 2.5)
        .to(pieImg[7], {
          opacity: 0,
          duration: 8
        }, '>-7')
        .fromTo(pieImg[1], {
          y: '-20vh',
          opacity: 0,
        }, {
          y: '90vh',
          opacity: 1,
          duration: 12
        }, 5)
        .to(pieImg[1], {
          opacity: 0,
          duration: 12
        }, '>-10')
        .fromTo(pieImg[4], {
          y: '-50vh',
          opacity: 0,
        }, {
          y: '40vh',
          opacity: 1,
          duration: 14
        }, 0.5)
        .to(pieImg[4], {
          opacity: 0,
          duration: 14
        }, '>-12')
        .to('[class*="quiz__question"][class*="question--7"] h1', {
          y: '15vh',
          ease: 'power4',
          duration: 3
        }, 6)
        .to('[class*="quiz__question"][class*="question--7"] p', {
          y: '15vh',
          opacity: 1,
          ease: 'power4',
          duration: 3
        }, 6.2)
        .to('[class*="quiz__question"][class*="question--7"] [class*="buttons"]', {
          y: '15vh',
          opacity: 1,
          ease: 'power4',
          duration: 3
        }, 6.4);
    }, quizStep7);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={quizStep7} className={`${styles.quiz__question} ${styles['question--7']}`}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles.content}>
          <h1>Have we whetted your appetite?</h1>
          <p>Well done for making it this far. Still hungry for more?</p>
          <div className={styles.buttons}>
            <Button
              type="link"
              size="m"
              color="white"
              link={`${location.pathname}/answer`}
            >
              Yes, I'm famished!
            </Button>
            <Button
              type="link"
              size="m"
              color="white"
              link={`${location.pathname}/answer`}
            >
              A bit peckish
            </Button>
            <Button
              type="link"
              size="m"
              color="white"
              link={`${location.pathname}/answer`}
            >
              Nope, I just ate
            </Button>
          </div>
        </div>
        <div className={styles.pics}>
          <WebpFallback 
            webp={QuizStep7_1}
            notWebp={QuizStep7_1Png}
            alt="Have we whetted your appetite?"
            className={`${styles['pic-pie']} ${styles['pic-pie-1']}`}
          />
          <WebpFallback 
            webp={QuizStep7_1}
            notWebp={QuizStep7_1Png}
            alt="Have we whetted your appetite?"
            className={`${styles['pic-pie']} ${styles['pic-pie-2']}`}
          />
          <WebpFallback 
            webp={QuizStep7_1}
            notWebp={QuizStep7_1Png}
            alt="Have we whetted your appetite?"
            className={`${styles['pic-pie']} ${styles['pic-pie-3']}`}
          />
          <WebpFallback 
            webp={QuizStep7_1}
            notWebp={QuizStep7_1Png}
            alt="Have we whetted your appetite?"
            className={`${styles['pic-pie']} ${styles['pic-pie-4']}`}
          />
          <WebpFallback 
            webp={QuizStep7_1}
            notWebp={QuizStep7_1Png}
            alt="Have we whetted your appetite?"
            className={`${styles['pic-pie']} ${styles['pic-pie-5']}`}
          />
          <WebpFallback 
            webp={QuizStep7_1}
            notWebp={QuizStep7_1Png}
            alt="Have we whetted your appetite?"
            className={`${styles['pic-pie']} ${styles['pic-pie-6']}`}
          />
          <WebpFallback 
            webp={QuizStep7_1}
            notWebp={QuizStep7_1Png}
            alt="Have we whetted your appetite?"
            className={`${styles['pic-pie']} ${styles['pic-pie-7']}`}
          />
          <WebpFallback 
            webp={QuizStep7_1}
            notWebp={QuizStep7_1Png}
            alt="Have we whetted your appetite?"
            className={`${styles['pic-pie']} ${styles['pic-pie-8']}`}
          />
        </div>
      </div>
    </div>
  )
}

export default QuizStep7