import { WebpFallback } from '../../';

import styles from './index.module.scss';

const QuizColleaguesCarouselItem = ({ item: { image, name, background, role, types } }) => {
  return (
    <div className={styles['colleagues-slider__item']}>
      <div className={styles.pic}>
        <WebpFallback 
          webp={image.webp}
          notWebp={image.notWebp}
          alt={name}
        />
      </div>
      <h2>{name}</h2>
      <div className={styles['info-block']}>
        <div className={styles.title}>Background:</div>
        <p>{background}</p>
      </div>
      <div className={styles['info-block']}>
        <div className={styles.title}>Present role:</div>
        <p>{role}</p>
      </div>
      {types && (
        <div className={styles['info-block']}>
          <div className={styles.title}>Type:</div>
          <div className={styles['icons-list']}>
            {types.map((icon, index) => (
              <div className={styles.icon} key={index}>
                {icon}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default QuizColleaguesCarouselItem