import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { MythsCarouselItem } from '../';
import { MythsCarouselData } from '../../utils/data';

import 'swiper/css';
import 'swiper/css/navigation';
import styles from './index.module.scss';

const MythsCarousel = ({ except }) => {
  return (
    <div className={styles['myths-slider']}>
      <Swiper
        className={styles.swiper}
        spaceBetween={20}
        slidesPerView={1}
        modules={[Navigation]}
        navigation={{
          nextEl: '[class*="myths-slider__nav__btn__next"]',
          prevEl: '[class*="myths-slider__nav__btn__prev"]'
        }}
        breakpoints={{
          769: {
            slidesPerView: 2
          },
          1025: {
            slidesPerView: 4,
            spaceBetween: 35
          }
        }}
      >
        {MythsCarouselData && MythsCarouselData.map(({image, imageHover, title}, index) => {
          if (except === undefined || except !== index){
            return (
              <SwiperSlide key={index}>
                <MythsCarouselItem 
                  image={image}
                  imageHover={imageHover}
                  index={index}
                  title={title}
                />
              </SwiperSlide>
            )
          }
          return '';
        })}
      </Swiper>
    </div>
  )
}

export default MythsCarousel