import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../components';
import { MythsDetailVideos } from '../../utils/data';
import { useBrowserHistory } from '../../context/BrowserHistoryStateContextProvider';

import { IconArrowRightWhite } from '../../utils/svgs';
import { MythDetail } from '../../utils/images';

import styles from './index.module.scss';
import buttonStyles from '../../components/Button/index.module.scss';

const MythDetailSection = ({ id }) => {
  const navigate = useNavigate();
  const { browserHistory, setBrowserHistory } = useBrowserHistory();

  const [currentVideo, setCurrentVideo] = useState(null);

  useEffect(() => {
    if(id){
      if(MythsDetailVideos[id - 1]){
        setCurrentVideo(MythsDetailVideos[id - 1]);
      } else {
        setCurrentVideo(null);
      }
    }    
  }, [id]);

  return (
    <section className={styles['myth-detail__section']}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles['myth-detail__inner']}>
          <header>
            <h1>Myth <span>#{id}</span></h1>
            <Button
              size="link"
              type="link"
              onClick={(e) => {
                e.preventDefault();
                if(browserHistory.length > 1) {
                  const prevItem = browserHistory[browserHistory.length - 2];
                  setBrowserHistory(prev => {
                    prev.pop();
                    return prev;
                  });
                  navigate(prevItem)
                }
              }}
            >
              <span className={buttonStyles.icon}>
                <IconArrowRightWhite />
              </span>
              <span>Back</span>
            </Button>
          </header>
          <div className={styles['myth-detail__video']}>
            {currentVideo ? (
              <video key={id} controls src={currentVideo} type="video/mp4" />
            ) : (
              <img src={MythDetail} alt={`Myth #${id}`} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default MythDetailSection