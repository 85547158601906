import { Outlet } from 'react-router-dom';

import { QuizHeader } from '../../components';
import { QuizContextProvider } from '../../context/QuizStateContextProvider';

import styles from './index.module.scss';

const Quiz = () => {
  return (
    <section className={styles.quiz__section}>
      <QuizHeader />
      <div className={styles.quiz__block}>
        <QuizContextProvider>
          <Outlet />
        </QuizContextProvider>
      </div>
    </section>
  )
}

export default Quiz