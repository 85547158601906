import { Link } from 'react-router-dom';

import { WebpFallback } from '../';

import styles from './index.module.scss';

const MythsCarouselItem = ({ index, title, image, imageHover }) => {
  return (
    <Link 
      to={`/myths/${index + 1}`} 
      className={styles['myths-slider__item']}
    >
      <div className={styles.pic}>
        <WebpFallback 
          webp={image.webp}
          notWebp={image.notWebp}
          alt={title}
        />
        {imageHover && (
          <video autoPlay loop muted poster={image.notWebp} playsInline className={styles['image-hover']} src={imageHover} type="video/mp4" />
        )}
        <button>
          <i className={styles['icon-play']}></i>
          <span>Play video</span>
        </button>
      </div>
      <div className={styles.text}>
        <div className={styles.subtitle}>MYTH #{index + 1}</div>
        <div className={styles.title}>{title}</div>
      </div>
    </Link>
  )
}

export default MythsCarouselItem