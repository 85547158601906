import { useRef, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizRadioRow, WebpFallback } from '../../../components';
import { GEMTypeRadiosData } from '../../../utils/data';
import { QuizStep3_1, QuizStep3_2, QuizStep3_1Png, QuizStep3_2Png } from '../../../utils/images';
import { useQuizContext } from '../../../context/QuizStateContextProvider';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep3 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const quizStep3 = useRef();
  const { checkboxesAnswersQty } = useQuizContext();
  const { width } = useWindowSize();

  useLayoutEffect(() => {
    // Quiz Step 3
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      const pFirst = document.querySelector('[class*="quiz__question"][class*="question--3"] p:nth-of-type(1)');
      const pSecond = document.querySelector('[class*="quiz__question"][class*="question--3"] p:nth-of-type(2)');

      tl.from('[class*="quiz__question"][class*="question--3"] [class*="pic-woman"]', {
          x: '-70vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        })
        .from('[class*="quiz__question"][class*="question--3"] [class*="pic-phone"]', {
          x: '50vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 0.5)
        .fromTo('[class*="quiz__question"][class*="question--3"] h1', {
          y: width > 678 ? '50vh' : '10vh',
          opacity: 0,
        },{
          y: width > 678 ? '20vh' : 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 1.5)
        .to('[class*="quiz__question"][class*="question--3"] [class*="pic-woman"]', {
          x: '-100vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 3.5)
        .to('[class*="quiz__question"][class*="question--3"] [class*="pic-phone"]', {
          x: '100vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 3.5)
        .to('[class*="quiz__question"][class*="question--3"] h1', {
          y: 0,
          ease: 'power1',
          duration: 2
        }, 4)
        .to(pFirst, {
          y: 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 4)
        .to(pSecond, {
          y: 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 4)
        .to('[class*="quiz__question"][class*="question--3"] [class*="quiz__custom-radios"]', {
          y: 0,
          opacity: 1,
          height: 'auto',
          visibility: 'visible',
          ease: 'power1',
          duration: 2
        }, 4.5)
        .to('[class*="quiz__question"][class*="question--3"] [class*="button-wrapper"]', {
          y: 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 5.5);
    }, quizStep3);
    return () => ctx.revert();
  }, [width]);

  return (
    <div ref={quizStep3} className={`${styles.quiz__question} ${styles['question--3']}`}>
      <div className={`layout ${styles['layout']}`}>
        <div className={styles.content}>
          <h1>Are you the GEM type?</h1>
          <p>People think that you need to be a specific type of person to work at GEM.<br/> So let's see how you stack up against some of our colleagues.</p>
          <p>Which do you prefer?</p>
          {GEMTypeRadiosData && (
            <div className={styles['quiz__custom-radios']}>
              {GEMTypeRadiosData.map((row, index) => (
                <QuizRadioRow row={row} key={index} />
              ))}
            </div>
          )}
          <div className={styles['button-wrapper']}>
            <Button
              color="blue"
              type="link"
              size="m"
              disabled={checkboxesAnswersQty.length !== GEMTypeRadiosData.length}
              onClick={(e) => {
                e.preventDefault();
                if(!(checkboxesAnswersQty.length !== GEMTypeRadiosData.length)){
                  navigate(`${location.pathname}/answer`)
                }
              }}
            >
              Proceed
            </Button>
          </div>
        </div>
        <div className={styles.pics}>
          <WebpFallback 
            webp={QuizStep3_1}
            notWebp={QuizStep3_1Png}
            alt="Are you the GEM type?"
            className={styles['pic-phone']}
          />
          <WebpFallback 
            webp={QuizStep3_2}
            notWebp={QuizStep3_2Png}
            alt="Are you the GEM type?"
            className={styles['pic-woman']}
          />
        </div>
      </div>
    </div>
  )
}

export default QuizStep3