import { SwiperSlide } from 'swiper/react';

import { QuizFeedbacksCarouselItem, SwiperSlider } from '../../';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from './index.module.scss';

const QuizFeedbacksCarousel = ({ items }) => {
  const { width } = useWindowSize();

  return (
    <div className={styles['quiz__cards-slider']}>
      {width && (
        <>
          {width > 768 ? (
            <SwiperSlider type='cards'>
              {items && items.map((item, index) => (
                <SwiperSlide key={index}>
                  <QuizFeedbacksCarouselItem item={item} />
                </SwiperSlide>
              ))}
            </SwiperSlider>
          ) : (
            <SwiperSlider>
              {items && items.map((item, index) => (
                <SwiperSlide key={index}>
                  <QuizFeedbacksCarouselItem item={item} />
                </SwiperSlide>
              ))}
            </SwiperSlider>
          )}
        </>
      )}
    </div>
  )
}

export default QuizFeedbacksCarousel