import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizFinalBlocksItem } from '../../../components';
import { QuizFinishBlocksData } from '../../../utils/data';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep8 = () => {
  const quizFinal = useRef();

  useLayoutEffect(() => {
    // Quiz Step 7 Answer
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from(quizFinal.current, {
        y: '20vh',
        opacity: 0,
        ease: 'power4',
        duration: 3
      });
    }, quizFinal);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={quizFinal} className={styles.quiz__final}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles['content-layout']}>
          <h1>Finished! Now what?</h1>
          <p>Thanks for completing our little quiz! We hope we've convinced you that GEM is an amazing place to work. If you're interested in exploring GEM in more depth, here are three tangible things you can do right now:</p>
        </div>
        <div className={styles['quiz__finish-blocks']}>
          {QuizFinishBlocksData && QuizFinishBlocksData.map((item, index) => (
            <QuizFinalBlocksItem 
              key={index}
              item={item}
            />
          ))}
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            color="blue"
            type="link"
            size="m"
            link={'/quiz'}
          >
            Try again
          </Button>
        </div>
      </div>
    </div>
  )
}

export default QuizStep8