import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Hero, HeroBottom, LinkBlocks, MythsCarouselSection, VideoSection } from '../../sections';
import { HOMELinkBlocksData } from '../../utils/data';

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const homePage = useRef();

  useLayoutEffect(() => {
    // Hero Bottom Section
    const ctx = gsap.context(() => {
      let stl = gsap.timeline({
        scrollTrigger: {
          trigger: '[class*="hero-bottom-text__section"]',
          scrub: 2,
          start: 'bottom bottom',
          end: 'bottom+=50% center'
        }
      });

      stl.from('[class*="hero-bottom-text__section"]', {
          y: '-20vh',
          opacity: 0,
          duration: 3
        })
        .from('[class*="hero-bottom-text__section"] [class*="line"]', {
          width: 0,
          opacity: 0,
          duration: 3
        });

      let stl1 = gsap.timeline({
        scrollTrigger: {
          trigger: '[class*="hero-bottom-text__section"]',
          scrub: 2,
          start: 'bottom top',
          end: '+=5%'
        }
      });

      stl1.to('[class*="hero-bottom-text__section"]', {
          y: '-10vh',
          opacity: 0,
          duration: 10
        });
    }, homePage);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Myths Carousel Section
    const ctx = gsap.context(() => {
      let stl = gsap.timeline({
        scrollTrigger: {
          trigger: '[class*="myths-slider__section"]',
          scrub: 2,
          start: 'top-=100% bottom',
          end: '+=10%'
        }
      });

      stl.from('[class*="myths-slider__section"]', {
          y: '50vh',
          opacity: 0,
          duration: 10
        });

      let stl1 = gsap.timeline({
        scrollTrigger: {
          trigger: '[class*="myths-slider__section"]',
          scrub: 2,
          start: 'bottom-=10% center',
          end: '+=10%'
        }
      });

      stl1.to('[class*="myths-slider__section"]', {
          y: '-10vh',
          opacity: 0,
          duration: 10
        });
    }, homePage);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Video Section
    const ctx = gsap.context(() => {
      let stl = gsap.timeline({
        scrollTrigger: {
          trigger: '[class*="myths-slider__section"]',
          scrub: 2,
          start: 'top+=70% bottom',
          end: '+=10%'
        }
      });

      stl.from('[class*="real-gem-video__section"]', {
        y: '20vh',
        opacity: 0,
        duration: 10
      });

      let stl1 = gsap.timeline({
        scrollTrigger: {
          trigger: '[class*="real-gem-video__section"]',
          scrub: 2,
          start: 'bottom+=50% center',
          end: '+=10%'
        }
      });

      stl1.to('[class*="real-gem-video__section"]', {
        y: '-10vh',
        opacity: 0,
        duration: 10
      });
    }, homePage);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Links Blocks Section
    const ctx = gsap.context(() => {
      const linkBlocksSections = document.querySelectorAll('[class*="link-blocks__section"]');

      linkBlocksSections.forEach(section => {
        let stl = gsap.timeline({
          scrollTrigger: {
            trigger: section,
            scrub: 2,
            start: 'top bottom-=30%',
            end: 'bottom bottom'
          }
        });

        stl.from(section, {
            opacity: 0,
            duration: 5
          });
      });
    }, homePage);
    return () => ctx.revert();
  }, []);

  return (
    <div className="home" ref={homePage}>
      <Hero />
      <HeroBottom />
      <MythsCarouselSection title={<>Let's <u>bust a few myths</u> about GEM</>} />
      <VideoSection />
      {HOMELinkBlocksData && HOMELinkBlocksData.map(({ left, right }, index) => (
        <LinkBlocks 
          key={index}
          left={left} 
          right={right} 
        />
      ))}
    </div>
  )
}

export default Home