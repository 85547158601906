import { Button, WebpFallback } from '../';

import styles from './index.module.scss';
import buttonStyles from '../Button/index.module.scss';

const LinkBlocksItem = ({ type, title, text, button, image }) => {
  return (
    <div className={styles['link-blocks__inner']}>
      {type === "columns" ? (
        <div className={styles['link-blocks__cols']}>
          <div className={styles.col}>
            <h2>{title}</h2>
            <Button
              type={button.type}
              size={button.size}
              color={button.color}
              link={button.link}
              newWindow={button.newWindow}
            >
              {button.icon ? (
                <>
                  <span>{button.text}</span>
                  <span className={buttonStyles.icon}>{button.icon}</span>
                </>
              ) : button.text}
            </Button>
          </div>
          <div className={styles.col}>
            <WebpFallback 
              webp={image.webp}
              notWebp={image.notWebp}
              alt={title}
            />
          </div>
        </div>
      ) : (
        <>
          <h2>{title}</h2>
          {text && (
            <p>{text}</p>
          )}
          <Button
            type={button.type}
            size={button.size}
            color={button.color}
            link={button.link}
            newWindow={button.newWindow}
          >
            {button.icon ? (
              <>
                <span>{button.text}</span>
                <span className={buttonStyles.icon}>{button.icon}</span>
              </>
            ) : button.text}
          </Button>
        </>
      )}
    </div>
  )
}

export default LinkBlocksItem