import { useParams } from 'react-router-dom';

import { QuizStep1, QuizStep2, QuizStep3, QuizStep4, QuizStep5, QuizStep6, QuizStep7 } from '../../../sections';

const QuizSteps = () => {
  let { step } = useParams();
  step = parseInt(step);

  return (
    <div className="quiz__item">
      {step === 1 && <QuizStep1 />}
      {step === 2 && <QuizStep2 />}
      {step === 3 && <QuizStep3 />}
      {step === 4 && <QuizStep4 />}
      {step === 5 && <QuizStep5 />}
      {step === 6 && <QuizStep6 />}
      {step === 7 && <QuizStep7 />}
    </div>
  )
}

export default QuizSteps