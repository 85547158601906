import styles from './index.module.scss';

const HeroBottom = () => {
  return (
    <section className={styles['hero-bottom-text__section']}>
      <div className={styles['hero-bottom-text__inner']}>
        <div className={`layout ${styles.layout}`}>
          <p className={styles['show-on-tablet']}>Somehow, we've developed a reputation for being, well, boring. Perhaps we haven't always done the best job of speaking up about who we are and what we do.</p>
          <p>Talk to someone at GEM and it's a different story. You'll discover a thriving, diverse practice (the Firm's biggest industry practice). And a culture based on people, authentic connections, mentorship, and opportunities for growth.</p>
          <p>Not to mention a wealth of projects in myriad industries. All imbued with a sense of purpose. The feeling that what we do has a tangible and positive impact on the world around us. That what we do matters.</p>
        </div>
        <div className={styles.line} />
      </div>
    </section>
  )
}

export default HeroBottom