import { Link } from 'react-router-dom';

import styles from './index.module.scss';

const Button = ({ type, size, color, link, children, disabled, newWindow, ...other }) => {
  const classNames = `${styles.btn} ${styles[`btn-${size}`]}${color ? ` ${styles[`btn-${color}`]}` : ''}${disabled ? ` ${styles.disabled}` : ''}`;

  return (
    <>
      {type === 'link' && (
        <Link to={link} className={classNames} disabled={disabled} target={newWindow ? '_blank' : ''} rel={newWindow ? 'noreferrer' : ''} {...other}>
          {children}
        </Link>
      )}
      {type === 'a' && (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={link} className={classNames} disabled={disabled} target={newWindow ? '_blank' : ''} rel={newWindow ? 'noreferrer' : ''} {...other}>
          {children}
        </a>
      )}
      {(!type || type === 'button') && (
        <button className={classNames} {...other} disabled={disabled} >
          {children}
        </button>
      )}
    </>
  )
}

export default Button