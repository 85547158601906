import { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, Textarea, QuizRandomQuote } from '../../../components';
import useWindowSize from '../../../hooks/useWindowSize';

import QuizStep4Video from '../../../assets/videos/quiz-step-4.mp4';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep4 = () => {
  const location = useLocation();
  const { width } = useWindowSize();

  const [timerPositive, setTimerPositive] = useState();
  const [timerNegative, setTimerNegative] = useState();

  const [prePositive, setPrePositive] = useState('');
  const [preNegative, setPreNegative] = useState('');

  const [positive, setPositive] = useState('');
  const [negative, setNegative] = useState('');

  const quizStep4 = useRef();
  
  const typeWriterEffect = (str, setValue) => {
    let index = 0;
    let newStr = '';

    const interval = setInterval(() => {
      if(index >= str.length - 1) clearInterval(interval);

      newStr += str[index];
      index++;
      setValue(newStr);
    }, 100);

    return interval;
  };

  useEffect(() => {
    clearTimeout(timerPositive);
    clearTimeout(timerNegative);
    
    if(prePositive) {
      setTimerPositive(typeWriterEffect(prePositive, setPositive));
    } else {
      setPositive(prePositive);
    }
    if(preNegative) {
      setTimerNegative(typeWriterEffect(preNegative, setNegative));
    } else {
      setNegative(preNegative);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prePositive, preNegative])

  useLayoutEffect(() => {
    // Quiz Step 4
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.to('[class*="quiz__question"][class*="question--4"] [class*="text-content"] h1', {
          y: width > 678 ? '20vh' : 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        })
        .to('[class*="quiz__question"][class*="question--4"] [class*="text-content"] h1', {
          y: 0,
          ease: 'power1',
          duration: 2
        }, 10)
        .from('[class*="quiz__question"][class*="question--4"] [class*="text-content"] p', {
          y: '20vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 10)
        .from('[class*="quiz__question"][class*="question--4"] [class*="quiz__feedback-fields"]', {
          y: '20vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 10)
        .from('[class*="quiz__question"][class*="question--4"] [class*="button-wrapper"]', {
          y: '20vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 10);
    }, quizStep4);
    return () => ctx.revert();
  }, [width]);

  return (
    <div ref={quizStep4} className={`${styles.quiz__question} ${styles['question--4']}`}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles.content}>
          <div className={styles['text-content']}>
            <h1>The good, the bad, and the GEM</h1>
            <p>We've heard a lot about GEM. Now we want to hear from you. <br/>Be brutally honest; we can handle it!</p>
          </div>
          <div className={styles['quiz__feedback-fields']}>
            <div className={styles['feedback-fields__cols']}>
              <div className={`${styles['feedback-fields__textarea']} ${styles.positive}`}>
                <Textarea 
                  id="positive-feedback"
                  label="A positive thing you've heard about GEM?"
                  value={positive}
                  setValue={setPositive}
                />
              </div>
              <div className={`${styles['feedback-fields__textarea']} ${styles.negative}`}>
                <Textarea 
                  id="negative-feedback"
                  label="A negative thing you've heard about GEM?"
                  value={negative}
                  setValue={setNegative}
                />
              </div>
            </div>
            <QuizRandomQuote 
              setPositive={setPrePositive}
              setNegative={setPreNegative}
            />
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              color="blue"
              type="link"
              size="m"
              link={`${location.pathname}/answer`}
            >
              Proceed
            </Button>
          </div>
        </div>
        <div className={styles.video}>
          <video autoPlay muted playsInline src={QuizStep4Video} type="video/mp4" />
        </div>
      </div>
    </div>
  )
}

export default QuizStep4