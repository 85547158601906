import ReactSlider from 'react-slider';

import styles from './index.module.scss';

const QuizSlider = ({ defaultValue, value, disabled = false, vertical, ...other }) => {
  return (
    <div className={`${styles.quiz__slider__wrapper} ${vertical ? styles['quiz__slider--vertical'] : ''}`}>
      <div className={styles.quiz__slider__text}>A little</div>
      <ReactSlider 
        defaultValue={defaultValue ? defaultValue : 0}
        value={value}
        disabled={disabled}
        className={styles.quiz__slider}
        thumbClassName={styles.quiz__slider__thumb}
        trackClassName="quiz__slider__track"
        orientation={vertical ? 'vertical' : 'horizontal'}
        invert={vertical}
        renderThumb={(props, state) => (
          <div {...props}>
            <span>You</span>
            {!disabled && <div className={styles.tooltip}>Move the dot</div>}
          </div>
        )}
        {...other}
      />
      <div className={styles.quiz__slider__text}>A lot</div>
    </div>
  )
}

export default QuizSlider