import { useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { MythDetailSection, MythsCarouselSection } from '../../sections';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const MythDetail = () => {
  const { id } = useParams();
  const mythDetail = useRef();

  useLayoutEffect(() => {
    // Video Section
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from('[class*="myth-detail__section"] header', {
          y: '15vh',
          opacity: 0,
          ease: 'power4',
          duration: 1
        })
        .from('[class*="myth-detail__section"] [class*="myth-detail__video"]', {
          y: '15vh',
          opacity: 0,
          ease: 'power2',
          duration: 2
        }, 0.3);
    }, mythDetail);
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    // Myths Carousel Section
    const ctx = gsap.context(() => {
      let stl = gsap.timeline({
        scrollTrigger: {
          trigger: '[class*="myth-detail__section"]',
          scrub: 2,
          start: 'bottom bottom',
          end: '+=50%'
        }
      });

      stl.from('[class*="myths-slider__section"]', {
          y: '50vh',
          opacity: 0,
          duration: 5
        });
    }, mythDetail);
    return () => ctx.revert();
  }, []);

  return (
    <div className={styles['myth-detail__page']} ref={mythDetail}>
      <MythDetailSection id={id} />
      <MythsCarouselSection 
        title={<><u>Bust some</u> more myths</>} 
        text="There are a few myths floating around about GEM. We think it's time to set the record straight." 
        except={id - 1}
      />
    </div>
  )
}

export default MythDetail