import { useState } from 'react';

import { QuizRadioIcon } from '../../';

import styles from './index.module.scss';

const QuizRadioRow = ({ row }) => {
  const [selected, setSelected] = useState(null);

  return (
    <div className={styles['custom-radios__row']}>
      {row && row.map(({ name, icon, title }, index) => (
        <QuizRadioIcon 
          name={name}
          icon={icon}
          title={title}
          key={index}
          setSelected={setSelected}
          selected={selected}
        />
      ))}
    </div>
  )
}

export default QuizRadioRow