import { LinkBlocksItem } from '../../components';

import styles from './index.module.scss';

const LinkBlocks = ({ left, right }) => {
  return (
    <section className={styles['link-blocks__section']}>
      <div 
        className={`${styles['link-blocks__left']} ${left.textColor === '#fff' ? styles.white : ''}`} 
        style={{
          backgroundColor: left.bg.color ? left.bg.color : '#fff',
          backgroundImage: left.bg.image ? `url(${left.bg.image.webp}), url(${left.bg.image.notWebp})` : 'none',
          color: left.textColor ? left.textColor : '#000'
        }}
      >
        {left.bg.image && <i className={styles.shadow} />}
        <LinkBlocksItem 
          title={left.title}
          text={left.text}
          button={left.button}
        />
      </div>
      <div 
        className={`${styles['link-blocks__right']} ${right.type === "columns" ? styles['link-blocks__columns'] : ""} ${right.textColor === '#fff' ? styles.white : ''}`}
        style={{
          backgroundColor: right.bg.color ? right.bg.color : '#fff',
          backgroundImage: right.bg.image ? `url(${right.bg.image.webp}), url(${right.bg.image.notWebp})` : 'none',
          color: right.textColor ? right.textColor : '#000'
        }}
      >
        {right.bg.image && <i className={styles.shadow} />}
        <LinkBlocksItem 
          type={right.type}
          title={right.title}
          text={right.text}
          button={right.button}
          image={right.image}
        />
      </div>
    </section>
  )
}

export default LinkBlocks