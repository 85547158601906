import { useParams } from 'react-router-dom';

import { QuizStep1Answer, QuizStep2Answer, QuizStep3Answer, QuizStep4Answer, QuizStep6Answer, QuizStep7Answer } from '../../../sections';

const QuizAnswers = () => {
  let { step } = useParams();
  step = parseInt(step);

  return (
    <div className="quiz__item">
      {step === 1 && <QuizStep1Answer />}
      {step === 2 && <QuizStep2Answer />}
      {step === 3 && <QuizStep3Answer />}
      {step === 4 && <QuizStep4Answer />}
      {step === 6 && <QuizStep6Answer />}
      {step === 7 && <QuizStep7Answer />}
    </div>
  )
}

export default QuizAnswers