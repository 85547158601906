import { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizYesNo, WebpFallback } from '../../../components';
import { QuizYesNoData } from '../../../utils/data';
import { QuizStep5_1, QuizStep5_2, QuizStep5_1Png, QuizStep5_2Png } from '../../../utils/images';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const QuizStep5 = () => {
  const navigate = useNavigate();
  let { step } = useParams();
  let nextStep = parseInt(step) + 1;
  const { width } = useWindowSize();
  const [filteredItems, setFilteredItems] = useState([]);

  const quizStep5 = useRef();

  useEffect(() => {
    const notGemItems = QuizYesNoData?.notGEM[random(0, QuizYesNoData?.notGEM.length - 1)];
    const gemItems = [];
    while(gemItems.length < 4){
      const randomGEMItem = QuizYesNoData?.GEM[random(0, QuizYesNoData?.GEM.length - 1)]
      if(!gemItems.includes(randomGEMItem)) {
        gemItems.push(randomGEMItem);
      } else {
        continue;
      }
    }
    const newItems = [notGemItems, ...gemItems];
    shuffleArray(newItems);
    setFilteredItems(newItems);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // Quiz Step 5
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      const pFirst = document.querySelector('[class*="quiz__question"][class*="question--5"] p:nth-of-type(1)');
      const pSecond = document.querySelector('[class*="quiz__question"][class*="question--5"] p:nth-of-type(2)');

      tl.from('[class*="quiz__question"][class*="question--5"] [class*="pic-hiddenbox"]', {
          x: '-70vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        })
        .from('[class*="quiz__question"][class*="question--5"] [class*="pic-box"]', {
          x: '50vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 0.5)
        .fromTo('[class*="quiz__question"][class*="question--5"] h1', {
          y: width > 768 ? '50vh' : '10vh',
          opacity: 0,
        },{
          y: width > 768 ? '20vh' : 0, 
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 1.5)
        .to('[class*="quiz__question"][class*="question--5"] [class*="pic-hiddenbox"]', {
          x: '-100vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 4)
        .to('[class*="quiz__question"][class*="question--5"] [class*="pic-box"]', {
          x: '100vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 4)
        .to('[class*="quiz__question"][class*="question--5"] h1', {
          y: 0,
          ease: 'power1',
          duration: 2
        }, 4)
        .to(pFirst, {
          y: 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 4)
        .to(pSecond, {
          y: 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 4)
        .to('[class*="quiz__question"][class*="question--5"] [class*="quiz__yes-no__wrapper"]', {
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power1',
          duration: 2
        }, 4)
        .to('[class*="quiz__question"][class*="question--5"] [class*="button-wrapper"]', {
          y: 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 4);
    }, quizStep5);
    return () => ctx.revert();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <div ref={quizStep5} className={`${styles.quiz__question} ${styles['question--5']}`}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles.content}>
          <h1>GEM project or not?</h1>
          <p>We get the feeling that people don't really know what we do. They might have an idea, but it's not always accurate.</p>
          <p>Think you know GEM? See if you can spot the GEM projects below.</p>
          <div className={styles['quiz__yes-no__wrapper']}>
            <QuizYesNo items={filteredItems} />
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              color="blue"
              type="link"
              size="m"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/quiz/${nextStep}`)
              }}
            >
              Proceed
            </Button>
          </div>
        </div>
        <div className={styles.pics}>
          <WebpFallback 
            webp={QuizStep5_1}
            notWebp={QuizStep5_1Png}
            alt="GEM project or not?" 
            className={styles['pic-box']}
          />
          <WebpFallback 
            webp={QuizStep5_2}
            notWebp={QuizStep5_2Png}
            alt="GEM project or not?" 
            className={styles['pic-hiddenbox']}
          />
        </div>
      </div>
    </div>
  )
}

export default QuizStep5