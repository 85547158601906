
// Import SVG's
import { IconArrowRightWhite, IconBike, IconCar, IconCoffee, IconEmail, IconEvening, IconLoud, IconMorning, IconQuiet, IconSlack, IconTea, IconWalk, IconWater } from './svgs';

// Import Images
import { 
  MythCarousel1, MythCarousel2, MythCarousel3, MythCarousel4, MythCarousel5, 
  MythCarousel1Png, MythCarousel2Png, MythCarousel3Png, MythCarousel4Png, MythCarousel5Png, 
  ClientImpact1, ClientImpact2, ClientImpact1Png, ClientImpact2Png, 
  ColleagueCarousel1, ColleagueCarousel2, ColleagueCarousel3, ColleagueCarousel4, ColleagueCarousel5, 
  ColleagueCarousel1Png, ColleagueCarousel2Png, ColleagueCarousel3Png, ColleagueCarousel4Png, ColleagueCarousel5Png, 
  GEMColleaguesCarousel1, GEMColleaguesCarousel2, GEMColleaguesCarousel3, GEMColleaguesCarousel4, GEMColleaguesCarousel5, 
  GEMColleaguesCarousel1Png, GEMColleaguesCarousel2Png, GEMColleaguesCarousel3Png, GEMColleaguesCarousel4Png, GEMColleaguesCarousel5Png,
  QuizFinishBlocksEvent, QuizFinishBlocksEventPng, 
  HOMELinkBlocks1, HOMELinkBlocks2, HOMELinkBlocks1Png, HOMELinkBlocks2Png, 
  GEMPeople1, GEMPeople2, GEMPeople3, GEMPeople4, GEMPeople5, GEMPeople6, GEMPeople2Png, GEMPeople3Png, GEMPeople4Png, GEMPeople5Png, GEMPeople6Png,
  HeroPic1, HeroPic1Png, HeroPic2, HeroPic2Png, HeroPic3, HeroPic3Png, HeroPic4, HeroPic4Png, HeroPic5, HeroPic5Png 
} from './images';

// Import Videos
import GEMPeopleVideo from '../assets/videos/pic-gem-people-1.mp4';
import Myth1DetailVideo from '../assets/videos/myths/myth-1.mp4';
import Myth2DetailVideo from '../assets/videos/myths/myth-2.mp4';
import Myth3DetailVideo from '../assets/videos/myths/myth-3.mp4';
import Myth4DetailVideo from '../assets/videos/myths/myth-4.mp4';
import Myth5DetailVideo from '../assets/videos/myths/myth-5.mp4';
import Myth1Video from '../assets/videos/myths/myth-1--hover.mp4';
import Myth2Video from '../assets/videos/myths/myth-2--hover.mp4';
import Myth3Video from '../assets/videos/myths/myth-3--hover.mp4';
import Myth4Video from '../assets/videos/myths/myth-4--hover.mp4';
import Myth5Video from '../assets/videos/myths/myth-5--hover.mp4';

// Links for Header
export const HeaderLinks = [
  {
    link: '/',
    title: 'Home'
  },
  {
    link: '/organization',
    title: 'Organization'
  },
  {
    link: '/events',
    title: 'Events'
  },
  {
    link: '/news',
    title: 'News'
  },
  {
    link: '/people',
    title: 'People'
  },
  {
    customClass: 'link-gem',
    link: '/quiz',
    title: 'Choose GEM'
  }
];

// Hero Pics
export const heroPics = [
  {
    id: 0,
    webp: HeroPic1,
    notWebp: HeroPic1Png
  },
  {
    id: 1,
    webp: HeroPic2,
    notWebp: HeroPic2Png
  },
  {
    id: 2,
    webp: HeroPic3,
    notWebp: HeroPic3Png
  },
  {
    id: 3,
    webp: HeroPic4,
    notWebp: HeroPic4Png
  },
  {
    id: 4,
    webp: HeroPic5,
    notWebp: HeroPic5Png
  }
]

// Myths Carousel Data
export const MythsCarouselData = [
  {
    image: {
      webp: MythCarousel1,
      notWebp: MythCarousel1Png
    },
    imageHover: Myth1Video,
    title: "You'll end up working in the middle of nowhere"
  },
  {
    image: {
      webp: MythCarousel2,
      notWebp: MythCarousel2Png
    },
    imageHover: Myth2Video,
    title: "We drink oil for breakfast"
  },
  {
    image: {
      webp: MythCarousel3,
      notWebp: MythCarousel3Png
    },
    imageHover: Myth3Video,
    title: "GEM is boring"
  },
  {
    image: {
      webp: MythCarousel4,
      notWebp: MythCarousel4Png
    },
    imageHover: Myth4Video,
    title: "GEM is on the wrong side of climate change"
  },
  {
    image: {
      webp: MythCarousel5,
      notWebp: MythCarousel5Png
    },
    imageHover: Myth5Video,
    title: "GEM only serves large corporations"
  }
];

// Myths Detail Videos
export const MythsDetailVideos = [
  Myth1DetailVideo, Myth2DetailVideo, Myth3DetailVideo, Myth4DetailVideo, Myth5DetailVideo
];

// Quiz Pie Chart Initial Data
export const QuizPieChartInitialData = [
  {
    percent: 33,
    percentages: [0, 33],
    color: '#2251FF',
    text: {
      key: 'Small:',
      value: ['<$1bn in', 'revenue p.a.,']
    }
  },
  {
    percent: 34,
    percentages: [33, 67],
    color: '#00A9F4',
    text: {
      key: 'Large:',
      value: ['$10bn+']
    }
  },
  {
    percent: 33,
    percentages: [67, 100],
    color: '#051C2C',
    text: {
      key: 'Medium:',
      value: ['', '$1bn-$10bn']
    }
  }
];

// Client Impact Data
export const ClientImpactData = [
  {
    image: {
      webp: ClientImpact1,
      notWebp: ClientImpact1Png
    },
    link: 'https://www.mckinsey.com/business-functions/sustainability/our-insights/houston-as-the-epicenter-of-a-global-clean-hydrogen-hub',
    title: 'Houston as the epicenter of a global, clean hydrogen hub'
  },
  {
    image: {
      webp: ClientImpact2,
      notWebp: ClientImpact2Png
    },
    link: 'https://www.mckinsey.com/industries/metals-and-mining/how-we-help-clients/how-a-steel-plant-in-india-tapped-the-value-of-data-and-won-global-acclaim',
    title: 'A steel plant in India taps the value of data and wins global acclaim'
  }
];

// Enthusiasts Data
export const EnthusiastsData = [
  {
    label: "The Know-it-all",
    sliderValue: "30%",
    info: "Experts, gurus, and innovators. Focus is their thing"
  },
  {
    label: "The Techy",
    sliderValue: "4%",
    info: "Enthusiastic about technology (not just computer games!)"
  },
  {
    label: "The Jack of all trades",
    sliderValue: "55%",
    info: "Generalist consultants with a passion for GEM"
  },
  {
    label: "The Hands-on type",
    sliderValue: "5%",
    info: "All about no-nonsense implementation. Our clients' best friends!"
  },
  {
    label: "The Solution seeker",
    sliderValue: "6%",
    info: "Constantly on the lookout for the perfect solutions!"
  }
];

// Colleagues Carousel Data
export const ColleaguesCarouselData = [
  {
    image: {
      webp: ColleagueCarousel1,
      notWebp: ColleagueCarousel1Png
    },
    name: "Christél Muller",
    background: "Bachelor's in Industrial and Systems Engineering; Master's in Business Management. Early career in mining in South Africa, working in engineering and maintenance.",
    role: "Solution analyst in the ThroughputX team. I help clients develop a digital replica of their operations through multi-method simulation modelling.",
    types: [<IconMorning />, <IconTea />, <IconSlack />, <IconCar />, <IconQuiet />]
  },
  {
    image: {
      webp: ColleagueCarousel2,
      notWebp: ColleagueCarousel2Png
    },
    name: "Tapio Melgin",
    background: "MSc in Economics and Business Administration and CEMS MIM. Exchanges at HKUST and Tsinghua. Food-tech startup co-founder.",
    role: "Sustainable and green-carbon value propositions. Helping CSTs and clients on advanced bio/e-fuels. Convincing clients on our capabilities. Building a service line and developing talent internally towards sustainability strategy and green business-building.",
    types: [<IconEvening />, <IconCoffee />, <IconEmail />, <IconWalk />, <IconQuiet />]
  },
  {
    image: {
      webp: ColleagueCarousel5,
      notWebp: ColleagueCarousel5Png
    },
    name: "Hannah Ellix",
    background: "Political sciences and international relations. Prior work in policy research and analysis, and translating math & physics abstracts",
    role: "My passion is converting upgraded equipment reliability strategies into solid frontline maintenance execution. Given prior education & work experience, that's about as “crazy McKinsey journey” as they come!",
    types: [<IconEvening />, <IconWater />, <IconSlack />, <IconWalk />, <IconQuiet />]
  },
  {
    image: {
      webp: ColleagueCarousel3,
      notWebp: ColleagueCarousel3Png
    },
    name: "Minsu Kim",
    background: "PhD in Medical Sciences; BS Biological Science, Chemistry.",
    role: "GEM AP with pasion for transformation, above all digital transformation.",
    types: [<IconEvening />, <IconCoffee />, <IconEmail />, <IconCar />, <IconQuiet />]
  },
  {
    image: {
      webp: ColleagueCarousel4,
      notWebp: ColleagueCarousel4Png
    },
    name: "Madeleine Goerg",
    background: "German Studies, History, International Development, then European Politics",
    role: "Working with Oil & Gas and other resource intensive companies to contribute to net zero by reshaping portfolios and leveraging the hydrogen opportunity.",
    types: [<IconMorning />, <IconTea />, <IconEmail />, <IconBike />, <IconQuiet />]
  }
];

// Random Quotes Data
export const RandomQuotesData = {
  positive: [
    "GEM feels like one big family",
    "There's a real sense of adventure at GEM",
    "The work we do is meaningful",
    "You can make real, lasting and positive change in the world"
  ],
  negative: [
    "They send you to remote, boring locations",
    "You have to be a specialist in something technical and scientific to work at GEM",
    "Most GEM projects are for huge, faceless corporations",
    "GEM doesn't care about climate change"
  ]
}

// Feedbacks Carousel Data
export const FeedbacksCarouselData = [
  {
    title: "GEM feels like one big family",
    info: "(This one warms our hearts)",
    good: true
  },
  {
    title: "They send you to remote, boring locations",
    info: "(More like: adventurous and stunning!)",
    good: false
  },
  {
    title: "There's a real sense of adventure at GEM",
    info: "(Our projects are pretty special)",
    good: true
  },
  {
    small: true,
    title: "You have to be a specialist in something technical and scientific to work at GEM",
    info: "(Actually, we have quite a few lit majors)",
    good: false
  },
  {
    title: "The work we do is meaningful",
    info: "(We wholeheartedly agree!)",
    good: true
  },
  {
    title: "Most GEM projects are for huge, faceless corporations",
    info: "(Not true! We mostly work small and mid-sized clients)",
    good: false
  },
  {
    title: "You can make real, lasting and positive change in the world",
    info: "(We try our best)",
    good: true
  },
  {
    title: "GEM doesn't care about climate change",
    info: "(On the contrary, the work we do is essential to the solution)",
    good: false
  }
];

// GEM Colleagues Carousel Data
export const GEMColleaguesCarouselData = [
  {
    image: {
      webp: GEMColleaguesCarousel1,
      notWebp: GEMColleaguesCarousel1Png
    },
    title: "I am GEM",
    text: <p>I am thrilled to co-create opportunities to connect with women leaders and practitioners in GEM across Europe, strengthen our networks, and share client impact stories at platforms where women can connect and grow.</p>
  },
  {
    image: {
      webp: GEMColleaguesCarousel2,
      notWebp: GEMColleaguesCarousel2Png
    },
    title: "I am GEM",
    text: <p>The greatness of the GEM community is how much we care about each other. When need arises, colleagues reach out to see how they can open opportunities.<br/> The support from the mentors allows me to thrive in the Firm</p>
  },
  {
    image: {
      webp: GEMColleaguesCarousel3,
      notWebp: GEMColleaguesCarousel3Png
    },
    title: "I am GEM",
    text: <p>GCR GEM is a well-connected community. People work closely together and there's a strong feeling of family and belonging. With a combination of excitement, opportunities to drive impact, a tight GEM community and family support, I have plenty of reasons to stay in GEM.</p>
  },
  {
    image: {
      webp: GEMColleaguesCarousel4,
      notWebp: GEMColleaguesCarousel4Png
    },
    title: "I am GEM",
    text: <p>GEM events are always rewarding and energising. I especially enjoy reconnecting with old colleagues and meeting new ones, and learning about trends and technologies shaping industries that I do not work in every day. I would highly recommend GEM-focused programs as a great opportunity to learn and recharge.</p>
  },
  {
    image: {
      webp: GEMColleaguesCarousel5,
      notWebp: GEMColleaguesCarousel5Png
    },
    title: "I am GEM",
    text: <p>To me, GEM also stands for "Great Entrepreneurial Minds". I am passionate about driving the massive change the energy industry needs to undergo over the next decade. It's a unique chance to be entrepreneurial right on the forefront.</p>
  }
];

// GEM Type Radios Data
export const GEMTypeRadiosData = [
  [
    {
      name: 'daytime',
      icon: <IconMorning />,
      title: 'Morning'
    },
    {
      name: 'daytime',
      icon: <IconEvening />,
      title: 'Evening'
    }
  ],
  [
    {
      name: 'drink',
      icon: <IconCoffee />,
      title: 'Coffee'
    },
    {
      name: 'drink',
      icon: <IconTea />,
      title: 'Tea'
    }
  ],
  [
    {
      name: 'contact',
      icon: <IconEmail />,
      title: 'Email'
    },
    {
      name: 'contact',
      icon: <IconSlack />,
      title: 'Slack'
    }
  ],
  [
    {
      name: 'vehicle',
      icon: <IconBike />,
      title: 'Bike'
    },
    {
      name: 'vehicle',
      icon: <IconCar />,
      title: 'Car'
    }
  ],
  [
    {
      name: 'noize',
      icon: <IconLoud />,
      title: 'Loud'
    },
    {
      name: 'noize',
      icon: <IconQuiet />,
      title: 'Quiet'
    }
  ]
];

// Quiz Yes No Data
export const QuizYesNoData = {
  notGEM: [
    {
      title: "Helping an O&G industry association to negotiate the reduction of industry decarbonization targets with a regulator",
      yes: {
        title: "NO",
        text: <>This work would contradict our policy. We do not help clients justify a position that denies climate change or circumvents lower environmental targets. We also do not negotiate with regulators on behalf of clients. If you want to learn more, please review the <a href="https://firmrisk.intranet.mckinsey.com/guidance-pages/compacts" target="_blank" rel="noreferrer">GEM risk compacts</a> and <a href="https://firmrisk.intranet.mckinsey.com/system/files/policies/Lobbying%20and%20Interactions%20with%20Government%20Officials%20Policy.pdf" target="_blank" rel="noreferrer">Lobbying & Interactions with Government Officials Policy</a>.</>
      },
      no: {
        title: "That's correct.",
        text: <>We do not help clients justify a position that denies climate change or circumvents lower environmental targets. We also do not negotiate with regulators on behalf of clients. If you want to learn more, please review the <a href="https://firmrisk.intranet.mckinsey.com/guidance-pages/compacts" target="_blank" rel="noreferrer">GEM risk compacts</a> and <a href="https://firmrisk.intranet.mckinsey.com/system/files/policies/Lobbying%20and%20Interactions%20with%20Government%20Officials%20Policy.pdf" target="_blank" rel="noreferrer">Lobbying & Interactions with Government Officials Policy</a>.</>
      }
    },
    {
      title: "Supporting nuclear work in a country with clear dual-use ambition",
      yes: {
        title: "NO",
        text: <>This is work we will not do. We do not work on nuclear topics in countries with clear dual-use ambitions. If you want to learn more about our risk stance on nuclear work, please review the <a href="https://firmrisk.intranet.mckinsey.com/guidance-pages/compacts" target="_blank" rel="noreferrer">EPNG risk compact</a>.</>
      },
      no: {
        title: "That's correct.",
        text: <>We do not work on nuclear topics in countries with clear dual-use ambitions. If you want to learn more about our risk stance on nuclear work, please review the <a href="https://firmrisk.intranet.mckinsey.com/guidance-pages/compacts" target="_blank" rel="noreferrer">EPNG risk compact</a>.</>
      }
    },
    {
      title: "Helping an international petrochemical company reassess its business presence in Russia",
      yes: {
        title: "No longer.",
        text: <>We cannot serve any clients in relation to their operations in Russia. For more information, please see the <a href="https://mckinsey.ent.box.com/s/ntz0tosywy0q056i2c62gnvnst2nxd25" target="_blank" rel="noreferrer">Ukraine-Russia client service guidelines</a>.</>
      },
      no: {
        title: "That's correct.",
        text: <>We do not serve any clients in relation to their operations in Russia. For more information, please see the <a href="https://mckinsey.ent.box.com/s/ntz0tosywy0q056i2c62gnvnst2nxd25" target="_blank" rel="noreferrer">Ukraine-Russia client service guidelines</a>.</>
      }
    }
  ],
  GEM: [
    {
      title: "Upskilling hundreds of employees and innovating with analytics to achieve performance gains",
      yes: {
        title: "Correct!",
        text: <>We worked with a steel plant in India to build tech solutions, develop analytics talent, and make analytics part of everyone's day-to-day. The plant was recognised as a leading digital facility by the World Economic Forum! You can read more <a href="https://www.mckinsey.com/industries/metals-and-mining/how-we-help-clients/how-a-steel-plant-in-india-tapped-the-value-of-data-and-won-global-acclaim" target="_blank" rel="noreferrer">here</a>.</>
      },
      no: {
        title: "Not quite.",
        text: <>We actually worked with a steel plant in India to build tech solutions, develop analytics talent, and make analytics part of everyone's day-to-day. The plant was recognised as a leading digital facility by the World Economic Forum! You can read more <a href="https://www.mckinsey.com/industries/metals-and-mining/how-we-help-clients/how-a-steel-plant-in-india-tapped-the-value-of-data-and-won-global-acclaim" target="_blank" rel="noreferrer">here</a>.</>
      }
    },
    {
      title: "Helping a fixed-wing aerial imagery company that provides real-time agricultural insights to farmers to develop a new business model",
      yes: {
        title: "Correct!",
        text: <>In less than 6 months, we supported the successful design, build, and launch of a B2B product and helped the client to transition their business model from B2C to B2B. We also collaborated with the client team to build sales capabilities and accelerate product sales. We supported the client end-to-end, from defining the value proposition to developing unique sales offers and prepping for sales meetings.</>
      },
      no: {
        title: "Not quite.",
        text: <>GEM made considerable impact with this client. In less than 6 months, we supported the successful design, build, and launch of a B2B product and helped the client to transition their business model from B2C to B2B. We also collaborated with the client team to build sales capabilities and accelerate product sales. We supported the client end-to-end, from defining the value proposition to developing unique sales offers and prepping for sales meetings.</>
      }
    },
    {
      title: "Supporting a client that aspires to lead in solar and change the energy market with a rooftop revolution on its green business building",
      yes: {
        title: "Correct!",
        text: <>We established a ~30-month, McKinsey-client partnership based on achieved impact, and set up a multi-disciplinary McKinsey team for support.</>
      },
      no: {
        title: "Actually, yes.",
        text: <>And in an innovative way: We established a ~30-month, McKinsey-client partnership based on achieved impact, and set up a multi-disciplinary McKinsey team for support.</>
      }
    },
    {
      title: "Identifying CO2-equivalent emissions to reduce fishing costs",
      yes: {
        title: "Correct!",
        text: <>We supported a European commercial fishery to improve use of fuel and fleet time.</>
      },
      no: {
        title: "Actually...",
        text: <>GEM is also about aquaculture. We supported a European commercial fishery to improve use of fuel and fleet time.</>
      }
    },
    {
      title: "Helping a gas player find a role in a decarbonised world",
      yes: {
        title: "Correct!",
        text: <>We helped our client understand the economics and implications of various decarbonisation pathways for its network and sensitivities, and the role of low/zero carbon gases. We also helped identify the financial impact of low/zero carbon gases on asset-management strategy.</>
      },
      no: {
        title: "Not quite.",
        text: <>We helped our client understand the economics and implications of various decarbonization pathways for its network and sensitivities, and the role of low/zero carbon gases. We also helped identify the financial impact of low/zero carbon gases on asset-management strategy.</>
      }
    },
    {
      title: "Helping a major petrochemicals player design and implement a long-term plan for geographic expansion",
      yes: {
        title: "Correct!",
        text: <>By identifying its competitive advantages and product expertise, and screening out lower-value growth ideas, the company was able to quickly double its market cap and identify substantial additional upside potential.</>
      },
      no: {
        title: "Actually, yes.",
        text: <>By identifying its competitive advantages and product expertise, and screening out lower-value growth ideas, the company was able to quickly double its market cap and identify substantial additional upside potential.</>
      }
    },
    {
      title: "Developing a major decarbonisation strategy for a brown-to-green investment for the largest emitter in a country",
      yes: {
        title: "Correct!",
        text: <>A one-year decarbonisation strategy for the largest emitter in the country. This turned into a two-year comprehensive partnership, helping them deliver on their ambitions across all relevant areas, including asset strategy, asset capex execution, permitting, financing, government engagement, energy sourcing, organizational capabilities, etc.</>
      },
      no: {
        title: "Not quite.",
        text: <>We helped create a one-year decarbonisation strategy for the largest emitter in the country. This turned into a two-year comprehensive partnership, helping them deliver on their ambitions across all relevant areas, including asset strategy, asset capex execution, permitting, financing, government engagement, energy sourcing, organizational capabilities, etc.</>
      }
    },
    {
      title: "Supporting a clean-fuel startup with contractor engagement",
      yes: {
        title: "Correct!",
        text: <>Last year we helped a clean-fuel startup develop a contractor-engagement roadmap, including key decisions needed, plan for selection, evaluating potential contractors, sponsor-level engagement with EPCs, and contractor outreach/working sessions. We also identified risk-premium reduction strategies and developed the fact-base to support their negotiations.</>
      },
      no: {
        title: "Actually, yes.",
        text: <>Last year we helped a clean-fuel startup develop a contractor-engagement roadmap, including key decisions needed, plan for selection, evaluating potential contractors, sponsor-level engagement with EPCs, and contractor outreach/working sessions. We also identified risk-premium reduction strategies and developed the fact-base to support their negotiations.</>
      }
    },
    {
      title: "Working to convene organizations driving hydrogen ecosystem building in the Gulf",
      yes: {
        title: "Correct!",
        text: <>We are helping convene over 70 organisations to drive hydrogen-ecosystem building on the Gulf Coast.</>
      },
      no: {
        title: "Not quite.",
        text: <>McKinsey is helping convene over 70 organisations to drive hydrogen-ecosystem building on the Gulf Coast.</>
      }
    },
    {
      title: "Supporting an integrated company looking to develop a GHG strategy",
      yes: {
        title: "Correct!",
        text: <>We are supporting a North American, integrated, O&G company that recently completed a merger and is looking to develop a GHG strategy. This will help them meet a variety of stakeholder needs including, investors, regulators, and the general public. The results: net zero by 2050, and 35% emissions cut by 2035. </>
      },
      no: {
        title: "Actually, yes.",
        text: <>Correct! We are supporting a North American, integrated, O&G company that recently completed a merger and is looking to develop a GHG strategy. This will help them meet a variety of stakeholder needs including, investors, regulators, and the general public. The results: net zero by 2050, and 35% emissions cut by 2035. </>
      }
    }
  ]
};

// Quiz Finish Blocks Data
export const QuizFinishBlocksData = [
  {
    title: <>Sign<br/> me up!</>,
    text: "Ready to take this to the next level? We'd love to see you at one of our GEM programs.",
    button: {
      link: "https://gemlearning.intranet.mckinsey.com/",
      text: "Sign me up",
      color: "blue",
      newWindow: true
    }
  },
  {
    title: <>Join a<br/>GEM event!</>,
    text: "If you would like to meet some real-life GEM folks for a chat.",
    button: {
      link: "https://digicomms.intranet.mckinsey.com/gem/events",
      text: "Join a GEM event",
      color: "white",
      newWindow: true
    },
    image: {
      webp: QuizFinishBlocksEvent,
      notWebp: QuizFinishBlocksEventPng
    }
  },
  {
    title: "Reach out!",
    text: "Reach out to your local GEM hub leader or GEM ambassador to discuss opportunities.",
    textColor: "blue",
    centered: true,
    button: {
      link: "https://digicomms.intranet.mckinsey.com/gem/organization",
      text: "Reach out",
      color: "blue",
      newWindow: true
    }
  }
];

// HOME: Link Blocks Data
export const HOMELinkBlocksData = [
  {
    left: {
      title: "GEM impact",
      text: "Whether we're working with a huge client or a small, fledgling startup, we always aim to create big impact and an unrivalled experience on every project. Keen to find out more? Check out our case library:",
      bg: {
        color: '#2251FF'
      },
      textColor: '#fff',
      button: {
        type: 'a',
        size: 'link',
        color: 'white-black',
        link: 'https://mckinsey.box.com/s/utsdlff9r87v29i8880m89sxeorekmpz',
        text: 'The GEM impact case libraries',
        icon: <IconArrowRightWhite />
      }
    },
    right: {
      type: 'columns',
      title: <>GEM in<br />your city</>,
      bg: {
        color: '#F0F0F0'
      },
      textColor: '#051C2C',
      button: {
        type: 'a',
        size: 'm',
        color: 'blue',
        link: 'https://digicomms.intranet.mckinsey.com/gem/organization',
        text: <><span className="hide-on-tablet">Find your local GEM leader</span><span className="show-on-tablet">Learn more</span></>
      },
      image: {
        webp: HOMELinkBlocks1,
        notWebp: HOMELinkBlocks1Png,
      }
    }
  },
  {
    left: {
      title: <>Meet a few<br/> GEM people</>,
      bg: {
        image: {
          webp: HOMELinkBlocks2,
          notWebp: HOMELinkBlocks2Png,
        }
      },
      textColor: '#fff',
      button: {
        type: 'a',
        size: 'm',
        color: 'white',
        link: '/people',
        text: 'I am GEM',
        newWindow: true
      }
    },
    right: {
      title: "Sign me up",
      text: "Ready to take this to the next level? We'd love to see you at one of our GEM programs.",
      bg: {
        color: '#00A9F4'
      },
      textColor: '#fff',
      button: {
        type: 'a',
        size: 'link',
        color: 'white',
        link: 'https://gemlearning.intranet.mckinsey.com/',
        text: 'Learn more',
        icon: <IconArrowRightWhite />
      }
    }
  }
];

// PEOPLE: GEM People Data
export const GEMPeopleData = [
  {
    title: "I am GEM",
    content: <>
      <p>Our colleagues respresent so many different professional and personal backgrounds that there's really no such thing as a GEM type.</p>
      <p>So what's it like to work at GEM? We'll let them tell you...</p>
    </>,
    bgColor: '#14284A',
    image: {
      webp: GEMPeople1,
      notWebp: GEMPeople1
    },
    video: GEMPeopleVideo
  },
  {
    title: "A truly supportive community",
    content: <>
      <p>“Having a great GEM sponsor like Giorgio is like having a coach, manager, and wingman all in one. It is truly inspiring. And it makes paying sponsorship forward feel natural. I now want do my part in ensuring that our community continues to support and create opportunities for all people at the Firm.”​</p>
      <p>“The outstanding thing about working at GEM is the people! There is a truly caring community of leaders and mentors. People like Ricky, who stay with you at each stage of the journey and provide the coaching and guidance that's so important to develop and grow.”</p>
    </>,
    bgColor: '#2251FF',
    image: {
      webp: GEMPeople2,
      notWebp: GEMPeople2Png
    },
    info: [
      {
        name: 'Giorgio Bresciani',
        position: 'Senior Partner in London'
      },
      {
        name: 'Ricky Asemota',
        position: 'AP in Lagos'
      },
      {
        name: 'Mudi Ori-Jesu',
        position: 'EM in Vancouver'
      }
    ]
  },
  {
    title: "Tackling the greatest challenges of our times",
    content: <>
      <p>“Women are the backbone of agricultural and food systems around the world, particularly in Africa. Yet so many remain poor, and millions go hungry every day. As part of the founding team of the African Agricultural Transformation Initiative – a multi-year partnership with AGRA, BMGF, and IFAD to transform agriculture in Africa – I couldn't pass up the opportunity to bring the best of the Firm to one of the greatest challenges of our time. I feel very lucky to apply my public-sector heart and private-sector mind to my passions every day, and be at the forefront of the Firm's efforts to build knowledge in digital capabilities for public-sector agriculture clients”.</p>
    </>,
    bgColor: '#00A9F4',
    image: {
      webp: GEMPeople3,
      notWebp: GEMPeople3Png
    },
    info: [
      {
        name: 'Amandla Ooko-Ombaka',
        position: 'Associate Partner in Nairobi'
      }
    ]
  },
  {
    title: "A sponsor and a mentor",
    content: <>
      <p>“Matt became my sponsor on week two of my transfer to London and the relationship was forged through client service across ten countries globally. We share a passion for thinking about companies' impact and contributions to society, and their roles as leaders in the Energy Transition. Matt has been a mentor, sponsor, and all-round sounding board on how to make the most of the opportunities the Firm offers.”</p>
    </>,
    bgColor: '#051C2C',
    image: {
      webp: GEMPeople4,
      notWebp: GEMPeople4Png
    },
    info: [
      {
        name: 'Matt Stone',
        position: 'GEM Partner in London'
      },
      {
        name: 'Madeleine Goerg',
        position: 'GEM AP in London'
      }
    ]
  },
  {
    title: "Using today to change our tomorrow",
    content: <>
      <p>“Building a green and sustainable future is one of the world's key challenges. Changing tomorrow is only possible if all diverse profiles are given the right opportunities to actively drive the change.”</p>
    </>,
    bgColor: '#2251FF',
    image: {
      webp: GEMPeople5,
      notWebp: GEMPeople5Png
    },
    info: [
      {
        name: 'Cristina Dexeus',
        position: 'Associate Partner in Madrid'
      },
      {
        name: 'Patricia Ovidio',
        position: 'Engagement Manager in Lisbon'
      }
    ]
  },
  {
    title: "Want to hear more from our GEM colleagues?",
    button: {
      type: 'a',
      size: 'm',
      color: 'blue',
      text: 'Visit I am GEM',
      newWindow: true,
      link: 'https://mckinsey.box.com/s/v9jjmqv8yez24rz89l69w63nxclb5gh8'
    },
    bgColor: '#00A9F4',
    image: {
      webp: GEMPeople6,
      notWebp: GEMPeople6Png
    }
  }
];