import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const QuizContextProvider = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [sliderValue, setSliderValue] = useState(0);
  const [checkboxesAnswersQty, setCheckboxesAnswersQty] = useState([]);

  return (
    <StateContext.Provider value={{
      currentIndex, setCurrentIndex,
      currentAnswer, setCurrentAnswer,
      sliderValue, setSliderValue,
      checkboxesAnswersQty, setCheckboxesAnswersQty
    }}>
      {children}
    </StateContext.Provider>
  )
}

export const useQuizContext = () => useContext(StateContext);