import { QuizEnthusiastsSlider } from '../../';

import styles from './index.module.scss';

const QuizEnthusiasts = ({ list }) => {
  return (
    <div className={styles.enthusiasts__list}>
      {list && list.map(({ label, sliderValue }, index) => (
        <div className={styles.enthusiasts__row} key={index}>
          <div className={styles.enthusiasts__label}>{label} - {sliderValue}</div>
          <QuizEnthusiastsSlider 
            value={sliderValue}
          />
        </div>
      ))}
    </div>
  )
}

export default QuizEnthusiasts