import styles from './index.module.scss';

const QuizEnthusiastsInfo = ({ list }) => {
  return (
    <div className={styles.enthusiasts__info}>
      {list && list.map(({ label, info }, index) => (
        <div key={index}>
          <strong>{label}</strong> – {info}
        </div>
      ))}
    </div>
  )
}

export default QuizEnthusiastsInfo