import { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { QuizSteps, QuizAnswers } from './components';
import { QuizStep8 } from './sections';
import { Home, MythDetail, People, Quiz } from './pages';
import { useBrowserHistory } from './context/BrowserHistoryStateContextProvider';

import './App.scss';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { browserHistory, setBrowserHistory } = useBrowserHistory();

  useEffect(() => {
    const currentPath = sessionStorage.getItem('currentPathName');
    if(currentPath){
      navigate(currentPath, { replace: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const startQuiz = urlParams.get('startquiz')

    if(window.location.pathname === '/people'){
      navigate('/people', { replace: true })
    } else if(startQuiz === '1') {
      navigate('/quiz', { replace: true })
    } else {
      navigate('/', { replace: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  useEffect(() => {
    sessionStorage.setItem('currentPathName', location.pathname);
    if(location.pathname !== '/quiz' && browserHistory[browserHistory.length - 1] !== location.pathname){
      setBrowserHistory((prev) => [...prev, location.pathname]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="App">
      <main>
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/myths/:id" element={<MythDetail />} />
          <Route path="/people" element={<People />} />
          <Route path="/quiz" element={<Quiz />}>
            <Route path="/quiz" element={<Navigate to="/quiz/1" replace={true} />} />
            <Route path="/quiz/final" element={<QuizStep8 />} />
            <Route path="/quiz/:step" element={<QuizSteps />} />
            <Route path="/quiz/:step/answer" element={<QuizAnswers />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;