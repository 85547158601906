import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { PeopleSection } from '../../sections';
import { GEMPeopleData } from '../../utils/data';
import useWindowSize from '../../hooks/useWindowSize';

gsap.registerPlugin(ScrollTrigger);

const People = () => {
  const peoplePage = useRef();
  const { width } = useWindowSize();

  useLayoutEffect(() => {
    // Links Blocks Section
    const ctx = gsap.context(() => {
      const peopleSections = document.querySelectorAll('[class*="gem-people-cols__section"]');

      peopleSections.forEach((section, index) => {
        if(width > 768){
          if(index === 0){
            let tl = gsap.timeline({delay: 0.5});
  
            tl.from(section.querySelector('[class*="col--left"]'), {
                x: '-100vh',
                ease: 'power1',
                duration: 0.7
              })
              .from(section.querySelector('[class*="col--right"]'), {
                opacity: 0,
                ease: 'power1',
                duration: 0.7
              }, 0.2);
          } else {
            let stl = gsap.timeline({
              scrollTrigger: {
                trigger: section,
                scrub: 2,
                start: 'top bottom',
                end: '+=50%',
                once: true
              }
            });
  
            if((index + 1) % 2 === 0){
              stl.from(section.querySelector('[class*="col--left"]'), {
                  opacity: 0,
                  duration: 10,
                  ease: 'power1',
                })
                .from(section.querySelector('[class*="col--right"]'), {
                  x: '100vh',
                  opacity: 0,
                  duration: 10,
                  ease: 'power1',
                });
            } else {
              stl.from(section.querySelector('[class*="col--left"]'), {
                  x: '-100vh',
                  opacity: 0,
                  duration: 10,
                  ease: 'power1',
                })
                .from(section.querySelector('[class*="col--right"]'), {
                  opacity: 0,
                  duration: 10,
                  ease: 'power1',
                });
            }
          }
        } else {
          let stl = gsap.timeline({
            scrollTrigger: {
              trigger: section,
              scrub: 2,
              start: 'top+=10% bottom',
              end: '+=50%',
              once: true
            }
          });

          stl.from(section.querySelector('[class*="col--left"]'), {
              opacity: 0,
              y: '10vh',
              duration: 2,
              ease: 'power1',
            })
            .from(section.querySelector('[class*="col--right"]'), {
              y: '10vh',
              opacity: 0,
              duration: 2,
              ease: 'power1',
            });
        }
      });
    }, peoplePage);
    return () => ctx.revert();
  }, [width]);

  return (
    <div ref={peoplePage}>
      {GEMPeopleData && GEMPeopleData.map((item, index) => (
        <PeopleSection
          key={index}
          item={item}
          even={(index + 1) % 2 === 0}
          first={index === 0}
        />
      ))}
    </div>
  )
}

export default People