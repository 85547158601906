import { Button } from '../../components';
import { VideoSectionPoster } from '../../utils/images';
import VideoGetToKnowRealGem from '../../assets/videos/video-get-to-know-the-real-GEM.mp4';

import styles from './index.module.scss';

const VideoSection = () => {
  return (
    <section className={styles['real-gem-video__section']}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles['real-gem-video__left']}>
          <video autoPlay muted loop playsInline poster={VideoSectionPoster} src={VideoGetToKnowRealGem} type="video/mp4" />
        </div>
        <div className={styles['real-gem-video__right']}>
          <h2>Get to know the<br />real GEM</h2>
          <p>We've designed a quiz to challenge some of the assumptions that are out there about us. It will help you get to know the real GEM. Who we are, what we do, and, most importantly, what we can do together.</p>
          <Button
            type="link"
            size="m"
            color="blue"
            link="/quiz"
          >
            Take the Quiz
          </Button>
        </div>
      </div>
    </section>
  )
}

export default VideoSection