import { Button, WebpFallback } from '../../components';
import { IconArrowRightWhite } from '../../utils/svgs';

import styles from './index.module.scss';
import buttonStyles from '../../components/Button/index.module.scss';

const PeopleSection = ({ item: {
  title, content, bgColor, image, info, button, video
}, even, first }) => {
  return (
    <section className={styles['gem-people-cols__section']}>
      {even ? (
        <>
          <div 
            className={`${styles['gem-people-cols__col']} ${styles['col--left']} ${styles['col--image']}`}
            style={{
              backgroundColor: bgColor ? bgColor : '#fff'
            }}
          >
            {video ? (
              <video autoPlay muted loop poster={image} playsInline src={video} type="video/mp4" />
            ) : (
              <WebpFallback 
                webp={image.webp}
                notWebp={image.notWebp}
                alt={title}
              />
            )}
          </div>
          <div className={`${styles['gem-people-cols__col']} ${styles['col--right']}`}>
            <div className={styles['gem-people-cols__inner']}>
              <h2>{title}</h2>
              {content}
              {button && (
                <Button
                  type={button.type}
                  size='m'
                  color='blue'
                  newWindow={button.newWindow}
                  link={button.link}
                >
                  {button.text}
                </Button>
              )}
              {info && (
                <div className={styles.info}>
                    {info.map(({ name, position }, index) => (
                      <div key={index}>
                        <b>{name},</b> {position}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`${styles['gem-people-cols__col']} ${styles['col--left']}`}>
            <div className={styles['gem-people-cols__inner']}>
              {first ? (
                <>
                  <Button
                    size="link"
                    type="a"
                    link="/gem-quiz"
                  >
                    <div className={buttonStyles.icon}>
                      <IconArrowRightWhite />
                    </div>
                    <span>Back</span>
                  </Button>
                  <h1>{title}</h1>
                </>
              ) : (
                <h2>{title}</h2>
              )}
              {content}
              {info && (
                <div className={styles.info}>
                    {info.map(({ name, position }, index) => (
                      <div key={index}>
                        <b>{name},</b> {position}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div 
            className={`${styles['gem-people-cols__col']} ${styles['col--right']} ${styles['col--image']}`}
            style={{
              backgroundColor: bgColor ? bgColor : '#fff'
            }}
          >
            {video ? (
              <video autoPlay muted loop poster={image} playsInline src={video} type="video/mp4" />
            ) : (
              <WebpFallback 
                webp={image.webp}
                notWebp={image.notWebp}
                alt={title}
              />
            )}
          </div>
        </>
      )}
      
    </section>
  )
}

export default PeopleSection