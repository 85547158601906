import { Navigation, Pagination, EffectCards } from 'swiper';
import { Swiper } from 'swiper/react';

import { IconArrowLeft } from '../../utils/svgs';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-cards";
import styles from './index.module.scss';
import buttonStyles from '../Button/index.module.scss';

const SwiperSlider = ({ type, children }) => {
  return (
    <div className={styles['swiper-slider__wrapper']}>
      <div className={styles['swiper-slider__nav']}>
        <button className={`${buttonStyles.btn} ${styles['swiper-slider__nav__btn']} ${styles['swiper-slider__nav__btn__prev']}`}>
          <div className={styles.icon}>
            <IconArrowLeft />
          </div>
          <div className={styles.title}>Previous</div>
        </button>
        <button className={`${buttonStyles.btn} ${styles['swiper-slider__nav__btn']} ${styles['swiper-slider__nav__btn__next']}`}>
          <div className={styles.icon}>
            <IconArrowLeft />
          </div>
          <div className={styles.title}>Next</div>
        </button>
      </div>

      {type === 'cards' ? (
        <Swiper
          className={styles.swiper}
          effect={"cards"}
          grabCursor={true}
          modules={[Navigation, Pagination, EffectCards]}
          slidesPerView={1}
          navigation={{
            nextEl: '[class*="swiper-slider__nav__btn__next"]',
            prevEl: '[class*="swiper-slider__nav__btn__prev"]'
          }}
          pagination={{ clickable: true }}
          cardsEffect={{
            perSlideOffset: 0,
            perSlideRotate: 0
          }}
          breakpoints={{
            769: {
              cardsEffect: {
                perSlideOffset: 5,
                perSlideRotate: 5,
                slideShadows: false
              }
            },
            1025: {
              cardsEffect: {
                perSlideOffset: 15,
                perSlideRotate: 5
              }
            }
          }}
        >
          {children}
        </Swiper>
      ) : (
        <Swiper
          className={styles.swiper}
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          spaceBetween={20}
          navigation={{
            nextEl: '[class*="swiper-slider__nav__btn__next"]',
            prevEl: '[class*="swiper-slider__nav__btn__prev"]'
          }}
          pagination={{ clickable: true }}
        >
          {children}
        </Swiper>
      )}
    </div>
  )
}

export default SwiperSlider