import { QuizYesNoAnswer } from '../../';
import { useQuizContext } from '../../../context/QuizStateContextProvider';

import styles from './index.module.scss';

const QuizYesNoItem = ({ item: { title, yes, no }, index, qty }) => {
  const { currentAnswer, currentIndex } = useQuizContext();

  return (
    <div className={styles['quiz__yes-no-questions__item'] + ((currentIndex === index + 1) && currentAnswer ? ` ${styles.flipped} ${styles['flipped-right']}` : '')}>
      <div className={styles['yes-no-questions__wrap']}>
        <div className={styles['yes-no-questions__inner']}>
          {!currentAnswer && (
            <div className={styles.question}>
              <div className={styles.subtitle}>PROJECT GOAL {index + 1}/{qty}</div>
              <h2>{title}</h2>
            </div>
          )}
          {currentAnswer && (
            <div className={styles.answer}>
              <QuizYesNoAnswer 
                data={currentAnswer === 'yes' ? yes : no}
                index={index}
                qty={qty}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default QuizYesNoItem