import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCube, EffectCards } from 'swiper';

import { QuizYesNoItem } from '../../';
import { useQuizContext } from '../../../context/QuizStateContextProvider';
import { IconNot, IconYes } from '../../../utils/svgs';
import useWindowSize from '../../../hooks/useWindowSize';

import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/effect-cards';
import styles from './index.module.scss';
import buttonStyles from '../../Button/index.module.scss';

const QuizYesNo = ({ items }) => {
  const { currentAnswer, setCurrentAnswer } = useQuizContext();
  const { width } = useWindowSize();

  return (
    <div className={styles['quiz__yes-no-questions']}>
      {items && (
        <div className={styles['quiz__yes-no-questions__items']}>
          {width && (
            <>
              {width > 768 ? (
                <Swiper 
                  slidesPerView={1}
                  allowTouchMove={false}
                  modules={[EffectCube]}
                  effect="cube"
                  cubeEffect={{
                    shadowOffset: 10,
                    shadowScale: 1.2
                  }}
                  onSlideChange={() => setCurrentAnswer(null)}
                >
                  {items.map((item, index) => (
                    <SwiperSlide key={index}>
                      <QuizYesNoItem 
                        item={item}
                        index={index}
                        qty={items.length}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <Swiper 
                  slidesPerView={1}
                  allowTouchMove={false}
                  modules={[EffectCards]}
                  effect="cards"
                  cardsEffect={{
                    perSlideOffset: 5,
                    perSlideRotate: 5,
                    slideShadows: false
                  }}
                  onSlideChange={() => setCurrentAnswer(null)}
                >
                  {items.map((item, index) => (
                    <SwiperSlide key={index}>
                      <QuizYesNoItem 
                        item={item}
                        index={index}
                        qty={items.length}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </>
          )}
        </div>
      )}
      <div className={styles.buttons + (currentAnswer === null ? ' animate__animated animate__fadeIn' : ` animate__animated__fast animate__fadeOut ${styles.animate__fadeOut}`)}>
        <button className={`${buttonStyles.btn} ${styles['btn-yes-no']} ${styles['btn-no']}`} onClick={() => setCurrentAnswer('not')}>
          <div className={styles['icon-circle']}>
            <IconNot />
          </div>
          <div className={styles.title}>Not a GEM project</div>
        </button>
        <button className={`${buttonStyles.btn} ${styles['btn-yes-no']} ${styles['btn-yes']}`} onClick={() => setCurrentAnswer('yes')}>
          <div className={styles['icon-circle']}>
            <IconYes />
          </div>
          <div className={styles.title}>A GEM project</div>
        </button>
      </div>
    </div>
  )
}

export default QuizYesNo