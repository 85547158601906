import { useSwiper } from 'swiper/react';

import { Button } from '../../';
import { useQuizContext } from '../../../context/QuizStateContextProvider';

import styles from './index.module.scss';

const QuizYesNoAnswer = ({ data: { title, text }, index, qty }) => {
  const swiper = useSwiper();
  const { setCurrentIndex } = useQuizContext();

  return (
    <div className={styles['quiz__yes-no-questions__answer']}>
      <div className={styles.inner}>
        <h2>{title}</h2>
        <p>{text}</p>
        {index + 1 !== qty && (
          <Button
            size="s2"
            color="white"
            onClick={() => {
              swiper.slideNext();
              setCurrentIndex(index + 2);
            }}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  )
}

export default QuizYesNoAnswer