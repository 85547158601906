import { useEffect, useLayoutEffect, useRef } from 'react';

const QuizPieChartSliderSlice = ({ slice, size, id, svg, setData, slices, svgWrap }) => {
  const dragCircle = useRef(null);
  
  const radius = size / 100 * 42;
  const { startPoints, endPoints, largeArcFlag, color } = slice;

  function getMousePosition(evt) {
    if (evt.touches) { evt = evt.touches[0]; }
    return [evt.clientX, evt.clientY];
  }
  
  // Getting coordinates (x & y) for percentage
  const getCoordinatesForPercent = (percent) => {
    const angle = (((360 / 100 * percent) - 90) / 360);
    const x = radius * Math.cos(2 * Math.PI * angle);
    const y = radius * Math.sin(2 * Math.PI * angle);

    return [x, y];
  }

  // Getting percentage from pointx x & y
  const getPercentageFromPoint = (x, y) => {
    if(x === 0) return (y > 0) ? 180 : 0;

    let angle = Math.atan(y / x) * 180 / Math.PI;
    angle = (x > 0) ? angle + 90 : angle + 270;
    const percent = angle / (360 / 100);

    return percent;
  }

  const getRadAngleFromPointA = (a, b) => {
    // Point C
    const c = [radius, 0];
    if((b[0] < 0 && b[1] < 0) || (b[0] > 0 && b[1] < 0)){
      c[0] = c[0] * -1;
    }
    // Vector AB
    const ab = [b[0] - a[0], b[1] - a[1]];
    // Vector AC
    const ac = [c[0] - a[0], c[1] - a[1]];
    // Cosinus A
    const cosA = (ab[0] * ac[0] + ab[1] * ac[1]) / (Math.sqrt(Math.pow(ab[0], 2) + Math.pow(ab[1], 2)) * Math.sqrt(Math.pow(ac[0], 2) + Math.pow(ac[1], 2)));
    // Angle A
    const angle = Math.acos(cosA);

    return angle;
  }

  // Getting percentage from points
  const getPercentageFromPoints = (a, b) => {
    const aPercent = getPercentageFromPoint(...a);
    const bPercent = getPercentageFromPoint(...b);

    let percent = 0;
    if(bPercent > aPercent){
      percent = bPercent - aPercent;
    } else if(bPercent < aPercent) {
      percent = bPercent + (100 - aPercent);
    }

    return percent;
  }
  
  // Getting points on circle
  const getPointsOnCircle = (a, b) => {
    const angle = getRadAngleFromPointA(a, b);

    // X & Y on circle
    const x = (b[1] > 0) ? radius * Math.cos(angle) : -radius * Math.cos(angle);
    const y = ((b[0] > 0 && b[1] < 0) || (b[0] < 0 && b[1] < 0)) ? -radius * Math.sin(angle) : radius * Math.sin(angle);

    return [x, y];
  }

  // Getting centroid of triangle
  const getTriangleCentroid = (a, b) => {
    const aAngle = (getPercentageFromPoint(...a) / 100 * 360);
    const bAngle = (getPercentageFromPoint(...b) / 100 * 360);
    const aAngleRad = aAngle * (Math.PI / 180);
    const bAngleRad = bAngle * (Math.PI / 180);

    let angle = 0;
    if(bAngle > aAngle){
      angle = (aAngleRad + bAngleRad) / 2 - Math.PI / 2;
    } else if(bAngle < aAngle) {
      angle = (aAngleRad + bAngleRad) / 2 + Math.PI / 2;
    }
    
    const x = radius / 2 * Math.cos(angle);
    const y = radius / 2 * Math.sin(angle);

    return [x, y];
  }

  // Dragging function
  const startDrag = (e) => {
    e.preventDefault();
    if((e.touches && e.touches[0].target.id === 'circle_' + id) || e.target.id === 'circle_' + id){
      const rect = {
        x: e.target.cx.baseVal.value,
        y: e.target.cy.baseVal.value
      };
      const dragOffset = {
        x: 0,
        y: 0
      };
      let point = svg.createSVGPoint();

      const [pointX, pointY] = getMousePosition(e);

      point.x = pointX;
      point.y = pointY;
      point = point.matrixTransform(svg.getScreenCTM().inverse());
      dragOffset.x = point.x - rect.x;
      dragOffset.y = point.y - rect.y;
    
      // Mousemove function
      const mousemove = (evt) => {
        if (!evt.touches) evt.preventDefault();

        if(!svgWrap.classList.contains('hide-tooltip')){
          svgWrap.classList.add('hide-tooltip');
        }

        const [pointX, pointY] = getMousePosition(evt);

        point.x = pointX;
        point.y = pointY;

        let cursor = point.matrixTransform(svg.getScreenCTM().inverse());
        rect.x = cursor.x - dragOffset.x;
        rect.y = cursor.y - dragOffset.y;

        // Get id for next slice
        const nextId = (id >= slices.length - 1) ? 0 : id + 1;
        // const prevId = (id === 0) ? slices.length - 1 : id - 1;
        
        // Get points on circle for small circles
        const [newX, newY] = getPointsOnCircle([0, 0], [rect.x, rect.y]);
        const currPercentageFromPoint = getPercentageFromPoint(newX, newY);
        const prevPercentageFromPoint = getPercentageFromPoint(...slice.startPoints);
        const nextPercentageFromPoint = getPercentageFromPoint(...slices[nextId].endPoints);
        
        if((
            (currPercentageFromPoint < prevPercentageFromPoint && (currPercentageFromPoint - prevPercentageFromPoint + 100) > 10) || 
            (currPercentageFromPoint - prevPercentageFromPoint > 10)
          ) &&
          (
            (currPercentageFromPoint > nextPercentageFromPoint && (nextPercentageFromPoint - currPercentageFromPoint + 100) > 10) || 
            (nextPercentageFromPoint - currPercentageFromPoint > 10)
        )){
          rect.x = newX;
          rect.y = newY;

          // Set small circles to be always on circle path
          if((evt.touches && evt.touches[0].target.id === 'circle_' + id) || evt.target.id === 'circle_' + id){
            evt.target.setAttribute('cx', rect.x);
            evt.target.setAttribute('cy', rect.y);
          }

          // Get new percent for current pie slice
          const endPoints = [rect.x, rect.y];

          const currentPercent = getPercentageFromPoints(slice.startPoints, endPoints);
          const nextPercent = getPercentageFromPoints(endPoints, slices[nextId].endPoints);

          setData(prevState => {
            const newState = prevState.map((obj, index) => {
              if(id === index){
                return {
                  ...obj, 
                  endPoints,
                  largeArcFlag: currentPercent > 50 ? 1 : 0,
                  percent: currentPercent,
                  textPoints: getTriangleCentroid(obj.startPoints, endPoints)
                }
              } else if (nextId === index){
                return {
                  ...obj, 
                  startPoints: endPoints,
                  percent: nextPercent,
                  largeArcFlag: nextPercent >= 50 ? 1 : 0,
                  textPoints: getTriangleCentroid(endPoints, obj.endPoints)
                }
              }
              return obj;
            })
            return newState;
          });
        }
      };
      
      const mouseup = () => {
        document.removeEventListener("mousemove", mousemove);
        document.removeEventListener("mouseup", mouseup);

        svg.removeEventListener('touchmove', mousemove);
        svg.removeEventListener('touchend', mouseup);
      };
      
      document.addEventListener("mousemove", mousemove);
      document.addEventListener("mouseup", mouseup);

      svg.addEventListener('touchmove', mousemove);
      svg.addEventListener('touchend', mouseup);
    }
  }

  useEffect(() => {
    const startPoints = getCoordinatesForPercent(slice.percentages[0]);
    const endPoints = getCoordinatesForPercent(slice.percentages[1]);
    const textPoints = getTriangleCentroid(startPoints, endPoints);

    setData(prevState => {
      const newState = prevState.map((obj, index) => {
        if(id === index){
          return {
            ...obj, 
            startPoints,
            endPoints,
            largeArcFlag: slice.percent > 50 ? 1 : 0,
            textPoints
          }
        }
        return obj;
      })
      return newState;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // Move dragging circle to the end of SVG to be always on the top of chart
    if(dragCircle.current){
      const fragment = document.createDocumentFragment();
      fragment.appendChild(dragCircle.current);
      svg.appendChild(fragment);
    }
  }, [dragCircle, svg]);

  return (
    <g className="slice">
      {startPoints && endPoints && (
        <>
          <path 
            d={`M ${startPoints[0]} ${startPoints[1]} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endPoints[0]} ${endPoints[1]} L 0 0`}
            fill={color}
          />
          <circle 
            ref={dragCircle}
            className="draggable-circle"
            id={'circle_' + id}
            r={size / 100 * 5.963}
            cx={endPoints[0]}
            cy={endPoints[1]}
            fill="#fff"
            stroke="#000"
            strokeWidth={size / 100 * 1.147}
            onMouseDown={startDrag}
            onTouchStart={startDrag}
          />
          <g transform={`translate(${slice.textPoints[0]}, ${slice.textPoints[1]})`}>
            <text
              textAnchor="middle"
              fill="#fff"
              fontSize={slice.percent > 20 ? '40' : '20'}
              fontFamily="Bower, sans-serif"
              style={{ transition: '.5s' }}
            >
              {Math.round(slice.percent)}%
            </text>
            <text
              style={{
                opacity: slice.percent > 20 ? '1' : '0',
                transition: '.5s'
              }}
            >
              {slice.text.value.length > 1 ? (
                <>
                  {slice.text.value.map((item, index) => {
                    if(index === 0){
                      return (
                        <tspan
                          key={index}
                          textAnchor="middle"
                          fill="#fff"
                          fontSize="12"
                          fontFamily="McKinseySans, sans-serif"
                          dy={20}
                        >
                          <tspan fontWeight="500">
                            {slice.text.key}
                          </tspan>
                          <tspan dx={3} fontWeight="400">
                            {item}
                          </tspan>
                        </tspan>
                      )
                    } else {
                      return (
                        <tspan
                          key={index}
                          textAnchor="middle"
                          fill="#fff"
                          fontSize="12"
                          fontFamily="McKinseySans, sans-serif"
                          dy={14}
                          x={0}
                        >
                          {item}
                        </tspan>
                      )
                    }
                  })}
                </>
              ) : (
                <tspan
                  textAnchor="middle"
                  fill="#fff"
                  fontSize="12"
                  fontFamily="McKinseySans, sans-serif"
                  dy={20}
                >
                  <tspan fontWeight="500">
                    {slice.text.key}
                  </tspan>
                  <tspan dx={3} fontWeight="400">
                    {slice.text.value[0]}
                  </tspan>
                </tspan>
              )}
            </text>
          </g>
          
        </>
      )}
    </g>
  )
}

export default QuizPieChartSliderSlice