import { useRef, useLayoutEffect, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizSlider, WebpFallback } from '../../../components';
import { useQuizContext } from '../../../context/QuizStateContextProvider';
import { QuizStep2_1, QuizStep2_2, QuizStep2_1Png, QuizStep2_2Png } from '../../../utils/images';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep2 = () => {
  const location = useLocation();
  const defaultValue = 35;
  const { sliderValue, setSliderValue } = useQuizContext();
  const quizStep2 = useRef();
  const { width } = useWindowSize();

  useEffect(() => {
    setSliderValue(defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // Quiz Step 2
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from('[class*="quiz__question"][class*="question--2"] [class*="pic-mountain"]', {
          y: '10vh',
          opacity: 0,
          ease: 'power1',
          duration: 1.5
        })
        .from('[class*="quiz__question"][class*="question--2"] [class*="pic-woman"]', {
          y: '40vh',
          ease: 'power1',
          duration: 2
        }, 0.2)
        .from('[class*="quiz__question"][class*="question--2"] [class*="pic-woman"]', {
          opacity: 0,
          ease: 'power1',
          duration: .5
        }, 0.2)
        .fromTo('[class*="quiz__question"][class*="question--2"] h1', {
          y: width > 768 ? '50vh' : '10vh',
          opacity: 0,
        },{
          y: width > 768 ? '20vh' : 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 1.5)
        .to('[class*="quiz__question"][class*="question--2"] [class*="pic-woman"]', {
          y: '40vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 4)
        .to('[class*="quiz__question"][class*="question--2"] [class*="pic-mountain"]', {
          y: '10vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 4)
        .to('[class*="quiz__question"][class*="question--2"] h1', {
          y: 0,
          ease: 'power1',
          duration: 2
        }, 5)
        .from('[class*="quiz__question"][class*="question--2"] p', {
          y: '20vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 5)
        .from('[class*="quiz__question"][class*="question--2"] [class*="slider-wrapper"]', {
          height: width > 678 ? '' : 0,
          y: '20vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 5)
        .from('[class*="quiz__question"][class*="question--2"] [class*="button-wrapper"]', {
          y: '20vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 5);
    }, quizStep2);
    return () => ctx.revert();
  }, [width]);
  
  return (
    <div ref={quizStep2} className={`${styles.quiz__question} ${styles['question--2']}`}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles.content}>
          <h1>How much sense of purpose did you get from your last engagement?</h1>
          <p>Purpose is a huge source of inspiration for people at McKinsey.<br/> But how much do you get from your work?</p>
          <div className={styles['slider-wrapper']}>
            <QuizSlider
              defaultValue={defaultValue}
              value={sliderValue}
              onAfterChange={(value) => setSliderValue(value)}
              vertical={!(width > 768)}
            />
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              color="blue"
              type="link"
              size="m"
              link={`${location.pathname}/answer`}
            >
              Proceed
            </Button>
          </div>
        </div>
        <div className={styles.pics}>
          <WebpFallback 
            webp={QuizStep2_1}
            notWebp={QuizStep2_1Png}
            alt="How much sense of purpose did you get from your last engagement?"
            className={styles['pic-mountain']}
          />
          <WebpFallback 
            webp={QuizStep2_2}
            notWebp={QuizStep2_2Png}
            alt="How much sense of purpose did you get from your last engagement?"
            className={styles['pic-woman']}
          />
        </div>
      </div>
    </div>
  )
}

export default QuizStep2