import styles from './index.module.scss';

const QuizEnthusiastsSlider = ({ value }) => {
  const valueNum = parseInt(value, 10);
  const reminder = valueNum % 10;

  return (
    <div className={styles.enthusiasts__slider}>
      <div 
        className={styles['enthusiasts__slider-inner']} 
        style={{
          width: value
        }}
      />
      {reminder !== 0 ? (
        <i 
          className={styles['icon-person--border-white']} 
          style={{ left: `${valueNum - reminder}%` }}
        />
      ) : ''}
    </div>
  )
}

export default QuizEnthusiastsSlider