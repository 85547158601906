import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { Button } from '../../';
import { useBrowserHistory } from '../../../context/BrowserHistoryStateContextProvider';
import { IconArrowLeftWhiteSm } from '../../../utils/svgs';

import styles from './index.module.scss';
import buttonStyles from '../../Button/index.module.scss';

const QuizHeader = () => {
  const navigate = useNavigate();
  let { step } = useParams();
  const [stepNum, setStepNum] = useState(null);
  const { browserHistory, setBrowserHistory } = useBrowserHistory();

  useEffect(() => {
    if(step){
      if(isNaN(step) || step > 7){
        navigate('/');
      } else {
        setStepNum(Number(step));
      }
    } else {
      setStepNum(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <header className={styles.quiz__header}>
      <Button
        type="link"
        size="link"
        color="white"
        onClick={(e) => {
          e.preventDefault();
          if(browserHistory.length > 1) {
            const prevItem = browserHistory[browserHistory.length - 2];
            setBrowserHistory(prev => {
              prev.pop();
              return prev;
            });
            navigate(prevItem)
          }
        }}
      >
        <span className={buttonStyles.icon}>
          <IconArrowLeftWhiteSm />
        </span>
        <span>Back</span>
      </Button>
      {stepNum && stepNum < 8 && (
        <div className={styles.pagination}>
          <span>{stepNum}</span>/<span>7</span>
        </div>
      )}
    </header>
  )
}

export default QuizHeader