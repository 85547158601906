import { useRef, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizPieChartSlider, WebpFallback } from '../../../components';
import { QuizStep1_1, QuizStep1_1Png, QuizStep1_1_Mob, QuizStep1_2, QuizStep1_2Png } from '../../../utils/images';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep1 = () => {
  const location = useLocation();
  const quizStep1 = useRef();
  const { width } = useWindowSize();
  
  useLayoutEffect(() => {
    // Quiz Step 1
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from('[class*="quiz__question"][class*="question--1"] [class*="pic-legs"]', {
          y: width > 768 ? '-100vh' : 0,
          x: width > 768 ? 0 : '50vw',
          opacity: 0,
          ease: 'power1',
          duration: 1.5
        })
        .from('[class*="quiz__question"][class*="question--1"] [class*="pic-woman"]', {
          y: width > 768 ? '50vh' : 0,
          x: width > 768 ? 0 : '-50vw',
          opacity: 0,
          ease: 'power1',
          duration: 1.5
        }, 0.2)
        .to('[class*="quiz__question"][class*="question--1"] h1', {
          y: 0,
          ease: 'power1',
          duration: 2
        }, 4.5)
        .to('[class*="quiz__question"][class*="question--1"] [class*="pic-legs"]', {
          y: width > 768 ? '-100vh' : 0,
          x: width > 768 ? 0 : '50vw',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 3)
        .to('[class*="quiz__question"][class*="question--1"] [class*="pic-woman"]', {
          y: width > 768 ? '50vh' : 0,
          x: width > 768 ? 0 : '-50vw',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 3)
        .fromTo('[class*="quiz__question"][class*="question--1"] h1 + p', {
          y: '20vh',
          opacity: 0
        },{
          y: 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 4.5)
        .fromTo('[class*="quiz__question"][class*="question--1"] [class*="chart-wrapper"]', {
          height: width > 678 ? '' : 0,
          y: '20vh',
          opacity: 0
        },{
          height: width > 678 ? '' : 'auto',
          y: 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 4.5)
        .fromTo('[class*="quiz__question"][class*="question--1"] [class*="btn-blue"]', {
          y: '20vh',
          opacity: 0
        },{
          y: 0,
          opacity: 1,
          ease: 'power1',
          duration: 2
        }, 4.5);
    }, quizStep1);
    return () => ctx.revert();
  }, [width]);

  return (
    <div ref={quizStep1} className={`${styles.quiz__question} ${styles['question--1']}`}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles.content}>
          <h1>Lots of people think that GEM only works with huge clients.</h1>
          <p>Sure, we work with a few big ones.<br/> But what does our client base really look like?</p>
          <div className={styles['chart-wrapper']}>
            <QuizPieChartSlider />
          </div>
          <Button
            color="blue"
            type="link"
            size="m"
            link={`${location.pathname}/answer`}
          >
            Proceed
          </Button>
        </div>
        <div className={styles.pics}>
          <WebpFallback 
            webp={width > 768 ? QuizStep1_1 : QuizStep1_1_Mob}
            notWebp={QuizStep1_1Png}
            alt="Lots of people think that GEM only works with huge clients."
            className={styles['pic-legs']}
          />
          <WebpFallback 
            webp={QuizStep1_2}
            notWebp={QuizStep1_2Png}
            alt="Lots of people think that GEM only works with huge clients."
            className={styles['pic-woman']}
          />
        </div>
      </div>
    </div>
  )
}

export default QuizStep1