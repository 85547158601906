import { useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button } from '../../../components';

import { ReactComponent as ChartImage } from '../../../assets/svg/q1-answer-chart.svg';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep1Answer = () => {
  let { step } = useParams();
  let nextStep = parseInt(step) + 1;
  const quizStep1Answer = useRef();

  useLayoutEffect(() => {
    // Quiz Step 1 Answer
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from(quizStep1Answer.current, {
          y: '40vh',
          opacity: 0,
          ease: 'power1',
          duration: 1
        });
    }, quizStep1Answer);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={quizStep1Answer} className={`${styles.quiz__answer} ${styles['answer--1']}`}>
      <div className={`layout ${styles.layout}`}>
        <h1>Close, here's our split for<br/> the last 5 years</h1>
        <p>A lot of us like working with big clients. And while they play an important role in our client activity, the majority of our clients are small or medium sized.</p>
        <p>In fact, some of our biggest impact has come from working with small clients.</p>
        <div className={styles['chart-wrapper']}>
          <ChartImage />
        </div>
        <div className={styles['btn-wrapper']}>
          <Button
            color="blue"
            type="link"
            size="m"
            link={`/quiz/${nextStep}`}
          >
            Next question
          </Button>
        </div>
      </div>
    </div>
  )
}

export default QuizStep1Answer