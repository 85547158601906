import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const BrowserHistoryContextProvider = ({ children }) => {
  const [browserHistory, setBrowserHistory] = useState([]);

  return (
    <StateContext.Provider value={{
      browserHistory, setBrowserHistory
    }}>
      {children}
    </StateContext.Provider>
  )
}

export const useBrowserHistory = () => useContext(StateContext);