import { useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizSliderCircle } from '../../../components';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep6Answer = () => {
  let { step } = useParams();
  let nextStep = parseInt(step) + 1;

  const quizStep6Answer = useRef();

  useLayoutEffect(() => {
    // Quiz Step 6 Answer
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from(quizStep6Answer.current, {
        y: '20vh',
        opacity: 0,
        ease: 'power1',
        duration: 1
      });
    }, quizStep6Answer);
    return () => ctx.revert();
  }, []);
  
  return (
    <div ref={quizStep6Answer} className={`${styles.quiz__answer} ${styles['answer--6']}`}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles['quiz__a6-cols']}>
          <div className={styles['a6-cols__left']}>
            <h1>Not enough.<br/> We want more!</h1>
            <p>
              <strong>We have big ambitions when it comes to diversity.</strong><br/>
              GEM is on the way to establishing itself as a lighthouse of diversity, equity and inclusion at McKinsey. We continue to create an environment of belonging and inclusion, where every colleague can thrive and develop by bringing their true self to work. 
            </p>
            <p>We've got hundreds of initiatives running, and there are many more to come!</p>
          </div>
          <div className={styles['a6-cols__right']}>
            <div className={styles['slider-wrapper']}>
              <QuizSliderCircle 
                disabled={true}
                initialValue={32}
              />
            </div>
          </div>
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            color="blue"
            type="link"
            size="m"
            link={`/quiz/${nextStep}`}
          >
            Next question
          </Button>
        </div>
      </div>
    </div>
  )
}

export default QuizStep6Answer