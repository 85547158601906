import { useQuizContext } from '../../../context/QuizStateContextProvider';

import styles from './index.module.scss';

const QuizRadioIcon = ({ name, icon, title, selected, setSelected }) => {
  const { setCheckboxesAnswersQty } = useQuizContext();

  return (
    <label className={`${styles['custom-radios__item']} ${selected === title ? styles.selected : ''}`}>
      <input 
        type="radio" 
        name={name} 
        value={title} 
        checked={selected === title}
        onChange={(e) => {
          setSelected(e.target.value);
          setCheckboxesAnswersQty(prev => {
            if(!prev.includes(e.target.name)) {
              return [...prev, e.target.name];
            }
            return prev;
          })
        }}
      />
      <div className={styles['custom-radios__inner']}>
        <div className={styles.icon}>
          {icon}
        </div>
        <div className={styles.title}>{title}</div>
      </div>
    </label>
  )
}

export default QuizRadioIcon