import { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizGemColleaguesCarousel } from '../../../components';
import { GEMColleaguesCarouselData } from '../../../utils/data';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep7Answer = () => {
  const quizStep7Answer = useRef();

  useLayoutEffect(() => {
    // Quiz Step 7 Answer
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from(quizStep7Answer.current, {
        y: '20vh',
        opacity: 0,
        ease: 'power1',
        duration: 2
      });
    }, quizStep7Answer);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={quizStep7Answer} className={`${styles.quiz__answer} ${styles['answer--7']}`}>
      <div className={`layout ${styles['layout']}`}>
        <h1>Here's what some of our colleagues think about GEM:</h1>
        <div className={styles['quiz__gem-colleagues-carousel__wrapper']}>
          <QuizGemColleaguesCarousel items={GEMColleaguesCarouselData} />
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            color="blue"
            type="link"
            size="m"
            link="/quiz/final"
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  )
}

export default QuizStep7Answer