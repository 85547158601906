import { SwiperSlide } from 'swiper/react';

import { QuizGemColleaguesCarouselItem, SwiperSlider } from '../../';

import styles from './index.module.scss';

const QuizGemColleaguesCarousel = ({ items }) => {
  return (
    <div className={styles['quiz__colleagues-gem-slider']}>
      <SwiperSlider>
        {items && items.map((item, index) => (
          <SwiperSlide key={index}>
            <QuizGemColleaguesCarouselItem item={item} />
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </div>
  )
}

export default QuizGemColleaguesCarousel