import { useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizFeedbacksCarousel } from '../../../components';
import { FeedbacksCarouselData } from '../../../utils/data';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep4Answer = () => {
  let { step } = useParams();
  let nextStep = parseInt(step) + 1;

  const quizStep4Answer = useRef();

  useLayoutEffect(() => {
    // Quiz Step 4 Answer
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from(quizStep4Answer.current, {
          y: '20vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        });
    }, quizStep4Answer);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={quizStep4Answer} className={`${styles.quiz__answer} ${styles['answer--4']}`}>
      <div className={`layout ${styles.layout}`}>
        <h1>Here's some of our favourite feedback, good and bad</h1>
        <div className={styles['quiz__feedback-carousel__wrapper']}>
          <QuizFeedbacksCarousel items={FeedbacksCarouselData} />
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            color="blue"
            type="link"
            size="m"
            link={`/quiz/${nextStep}`}
          >
            Next question
          </Button>
        </div>
      </div>
    </div>
  )
}

export default QuizStep4Answer