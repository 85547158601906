import { Button } from '../../';

import styles from './index.module.scss';

const QuizFinalBlocksItem = ({ item: { title, text, button, image, textColor, centered } }) => {
  return (
    <div 
      className={`${styles.quiz__finish__item} ${image ? styles['item--image'] : ''} ${centered ? styles['item--centered'] : ''}`}
      style={{ 
        backgroundImage: image ? `url(${image.webp}), url(${image.notWebp})` : 'none'
      }}
    >
      <div className={styles.content}>
        <h2>{title}</h2>
        <p className={textColor && styles[textColor]}>{text}</p>
        <Button
          type="a"
          size="s3"
          link={button.link}
          color={button.color}
          newWindow={button.newWindow}
        >
          {button.text}
        </Button>
      </div>
    </div>
  )
}

export default QuizFinalBlocksItem