import { useState, useRef } from 'react';

import QuizPieChartSliderSlice from '../QuizPieChartSliderSlice';
import { QuizPieChartInitialData } from '../../../utils/data';

import styles from './index.module.scss';

const QuizPieChartSlider = () => {
  const svgRef = useRef(null);
  const svgWrapRef = useRef(null);
  const [data, setData] = useState(QuizPieChartInitialData);
  
  const svgSize = 436;

  return (
    <div className={styles['pie-chart-slider__wrapper']} ref={svgWrapRef}>
      <svg 
        ref={svgRef}
        viewBox={`-${svgSize / 2} -${svgSize / 2} ${svgSize}  ${svgSize}`}
        preserveAspectRatio="xMidYMid meet"
        className={styles['svg-pie-chart-slider']}
      >
        <circle 
          r={svgSize / 100 * 45.872}
          fill="#fff"
        />
        <g className={styles.slices}>
          {data && data.map((slice, index) => (
            <QuizPieChartSliderSlice 
              key={index}
              id={index}
              svg={svgRef.current}
              size={svgSize}
              slice={slice}
              slices={data}
              setData={setData}
              svgWrap={svgWrapRef.current}
            />
          ))}
        </g>
      </svg>
    </div>
  )
}

export default QuizPieChartSlider