import styles from './index.module.scss';

const Textarea = ({ id, label, setValue, value }) => {
  return (
    <div className={styles['textarea-wrapper']}>
      <label htmlFor={id}>{label}</label>
      <textarea id={id} onChange={(e) => {setValue(e.target.value)}} value={value} />
    </div>
  )
}

export default Textarea