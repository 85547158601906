import styles from './index.module.scss';

const QuizFeedbacksCarouselItem = ({ item: { title, info, small, good } }) => {
  return (
    <div className={`${styles['cards-slider__card']} ${good ? styles['cards-slider__card--good'] : styles['cards-slider__card--bad']}`}>
      <h2 className={small && styles.small}>{title}</h2>
      <div className={styles.info}>{info}</div>
    </div>
  )
}

export default QuizFeedbacksCarouselItem