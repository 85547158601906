import { useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizEnthusiasts, QuizEnthusiastsInfo, QuizColleaguesCarousel } from '../../../components';
import { ColleaguesCarouselData, EnthusiastsData } from '../../../utils/data';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep3Answer = () => {
  let { step } = useParams();
  let nextStep = parseInt(step) + 1;
  const quizStep3Answer = useRef();

  useLayoutEffect(() => {
    // Quiz Step 3 Answer
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from(quizStep3Answer.current, {
          y: '20vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        });
    }, quizStep3Answer);
    return () => ctx.revert();
  }, []);

  return (
    <div ref={quizStep3Answer} className={`${styles.quiz__answer} ${styles['answer--3']}`}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles['content-layout']}>
          <h1>Turns out, we don't have a type!</h1>
          <p>We love working with all sorts of people. Regardless of professional or personal background. In fact, it's diversity that makes GEM such an innovative and impactful practice.</p>
          <p>Meet a few of our GEM colleagues:</p>
        </div>
        <div className={styles.quiz__answer__cols}>
          <div className={styles.col}>
            <div className={styles.quiz__enthusiasts__wrap}>
              <h2>GEM enthusiasts split</h2>
              <QuizEnthusiasts list={EnthusiastsData} />
              <QuizEnthusiastsInfo list={EnthusiastsData} />
            </div>
          </div>
          <div className={styles.col}>
            <QuizColleaguesCarousel items={ColleaguesCarouselData} />
          </div>
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            color="blue"
            type="link"
            size="m"
            link={`/quiz/${nextStep}`}
          >
            Next question
          </Button>
        </div>
      </div>
    </div>
  )
}

export default QuizStep3Answer