import { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse';

import { WebpFallback } from '../../components';
import { heroPics } from '../../utils/data';
import useWindowSize from '../../hooks/useWindowSize';

import { HeroBg, HeroBgPng, HeroMountains, HeroMountainsPng } from '../../utils/images';

import styles from './index.module.scss';

function randomIntFromInterval(max, min = 0) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
  const heroSection = useRef();
  const { width } = useWindowSize();

  const [heroPicsIndexes, setHeroPicsIndexes] = useState([]);

  useEffect(() => {
    const newPics = [];
    while(newPics.length < 2){
      const randomIndex = randomIntFromInterval(heroPics.length - 1);
      if(!newPics.includes(randomIndex)) newPics.push(randomIndex);
    }
    setHeroPicsIndexes(newPics);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // Hero Section
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from('[class*="hero__section"] [class*="hero__content"] h1', {
          opacity: 0,
          x: '-30vw',
          ease: 'power1',
          duration: 1
        })
        .from('[class*="hero__section"] [class*="hero__content"] p', {
          opacity: 0,
          x: '-30vw',
          ease: 'power1',
          duration: 1
        }, 0.5)
        .from('[class*="hero__section"] [class*="hero__pics__first"]', {
          y: '15vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        }, 1)
        .from('[class*="hero__section"] [class*="hero__pics__second"]', {
            y: '-15vh',
            opacity: 0,
            ease: 'power1',
            duration: 2
        }, 1);

      let stl = gsap.timeline({
        scrollTrigger: {
          trigger: '[class*="hero__section"]',
          scrub: 2,
          start: 'top top'
        }
      });
      
      stl.to('[class*="hero__section"] [class*="hero__content"]', {
          y: '-50vh',
          opacity: 0,
          duration: 3,
        });

    }, heroSection);
    return () => ctx.revert();
  }, [heroPicsIndexes]);

  return (
    <section ref={heroSection} className={styles.hero__section}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles.hero__content}>
          <h1>Think you know GEM?<small>Think again!</small></h1>
          <p>Ask around about GEM and you might get some confusing answers. <span className={styles['hide-on-tablet']}>Somehow, we've developed a reputation for being, well, boring.</span></p>
          <p className={styles['hide-on-tablet']}>Perhaps we haven't always done the best job of speaking up about who we are and what we do.</p>
        </div>
      </div>
      <MouseParallaxContainer
        className={styles.hero__pics}
        globalFactorX={0.3}
        globalFactorY={0.3}
        useWindowMouseEvents={true}
        enabled={width > 768}
      >
        <MouseParallaxChild
          factorX={0}
          factorY={0}
          className={styles.hero__bg__blue}
        >
          <WebpFallback 
            webp={HeroBg}
            notWebp={HeroBgPng}
            alt="Hero Bg"
          />
        </MouseParallaxChild>
        <MouseParallaxChild
          factorX={0.1}
          factorY={0.05}
          className={styles.hero__bg__mountains}
        >
          <WebpFallback 
            webp={HeroMountains}
            notWebp={HeroMountainsPng}
            alt="Hero Mountains"
          />
        </MouseParallaxChild>
        {heroPicsIndexes && heroPicsIndexes.map((item, index) => (
          <MouseParallaxChild
            factorX={index === 0 ? 0.2 : 0.7}
            factorY={index === 0 ? 0.2 : 0.7}
            key={index}
            className={index === 0 ? styles.hero__pics__first : styles.hero__pics__second}
          >
            <WebpFallback 
              webp={heroPics[item].webp}
              notWebp={heroPics[item].notWebp}
              alt="Hero Pic"
            />
          </MouseParallaxChild>
        ))}
      </MouseParallaxContainer>
    </section>
  )
}

export default Hero