import { useRef, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizSliderCircle, WebpFallback } from '../../../components';
import { QuizStep6_1, QuizStep6_2, QuizStep6_1Png, QuizStep6_2Png } from '../../../utils/images';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep6 = () => {
  const location = useLocation();
  const { width } = useWindowSize();
  const quizStep6 = useRef();

  useLayoutEffect(() => {
    // Quiz Step 6
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from('[class*="quiz__question"][class*="question--6"] [class*="pic-woman"]', {
        x: '-70vh',
        opacity: 0,
        ease: 'power1',
        duration: 3
      })
      .from('[class*="quiz__question"][class*="question--6"] [class*="pic-man"]', {
        x: '70vh',
        opacity: 0,
        ease: 'power1',
        duration: 3
      }, 0.5)
      .fromTo('[class*="quiz__question"][class*="question--6"] h1', {
        y: width > 768 ? '50vh' : '10vh',
        opacity: 0,
      },{
        y: width > 768 ? '20vh' : 0,
        opacity: 1,
        ease: 'power1',
        duration: 3
      }, 1.5)
      .to('[class*="quiz__question"][class*="question--6"] [class*="pic-woman"]', {
        x: '-100vh',
        opacity: 0,
        ease: 'power1',
        duration: 2
      }, 4)
      .to('[class*="quiz__question"][class*="question--6"] [class*="pic-man"]', {
        x: '100vh',
        opacity: 0,
        ease: 'power1',
        duration: 2
      }, 4)
      .to('[class*="quiz__question"][class*="question--6"] h1', {
        y: 0,
        ease: 'power1',
        duration: 2
      }, 4)
      .to('[class*="quiz__question"][class*="question--6"] p', {
        y: 0,
        opacity: 1,
        ease: 'power1',
        duration: 2
      }, 4)
      .to('[class*="quiz__question"][class*="question--6"] [class*="slider-wrapper"]', {
        y: 0,
        opacity: 1,
        height: 'auto',
        visibility: 'visible',
        ease: 'power1',
        duration: 2
      }, 4)
      .to('[class*="quiz__question"][class*="question--6"] [class*="button-wrapper"]', {
        y: 0,
        opacity: 1,
        ease: 'power1',
        duration: 2
      }, 4);
    }, quizStep6);
    return () => ctx.revert();
  }, [width]);

  return (
    <div ref={quizStep6} className={`${styles.quiz__question} ${styles['question--6']}`}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles.content}>
          <h1>What percentage of GEM client work is delivered by women?</h1>
          <p>Slide to answer</p>
          <div className={styles['slider-wrapper']}>
            <QuizSliderCircle />
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              color="blue"
              type="link"
              size="m"
              link={`${location.pathname}/answer`}
            >
              Proceed
            </Button>
          </div>
        </div>
        <div className={styles.pics}>
          <WebpFallback 
            webp={QuizStep6_1}
            notWebp={QuizStep6_1Png}
            alt="What percentage of GEM client work is delivered by women?"
            className={styles['pic-woman']}
          />
          <WebpFallback 
            webp={QuizStep6_2}
            notWebp={QuizStep6_2Png}
            alt="What percentage of GEM client work is delivered by women?"
            className={styles['pic-man']}
          />
        </div>
      </div>
    </div>
  )
}

export default QuizStep6