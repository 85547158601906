import { MythsCarousel } from '../../components';
import { IconArrowLeft } from '../../utils/svgs';

import styles from './index.module.scss';

const MythsCarouselSection = ({ title, text, except }) => {
  return (
    <section className={styles['myths-slider__section']}>
      <div className={`layout ${styles.layout}`}>
        <header>
          <h2>{title}</h2>
          {text && (<p>{text}</p>)}
          <div className={styles['myths-slider__nav']}>
            <button className={`${styles['myths-slider__nav__btn']} ${styles['myths-slider__nav__btn__prev']}`}>
              <div className={styles.icon}>
                <IconArrowLeft />
              </div>
              <div className={styles.title}>Previous</div>
            </button>
            <button className={`${styles['myths-slider__nav__btn']} ${styles['myths-slider__nav__btn__next']}`}>
              <div className={styles.icon}>
                <IconArrowLeft />
              </div>
              <div className={styles.title}>Next</div>
            </button>
          </div>
        </header>
        <MythsCarousel except={except} />
      </div>
    </section>
  )
}

export default MythsCarouselSection