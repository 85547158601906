import { useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, QuizClientImpactList, QuizSlider } from '../../../components';
import { useQuizContext } from '../../../context/QuizStateContextProvider';
import { ClientImpactData } from '../../../utils/data';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from './index.module.scss';

gsap.registerPlugin(ScrollTrigger);

const QuizStep2Answer = () => {
  let { step } = useParams();
  let nextStep = parseInt(step) + 1;
  const { sliderValue } = useQuizContext();
  const quizStep2Answer = useRef();
  const { width } = useWindowSize();

  useLayoutEffect(() => {
    // Quiz Step 2 Answer
    const ctx = gsap.context(() => {
      let tl = gsap.timeline({delay: 0.5});

      tl.from(quizStep2Answer.current, {
          y: '20vh',
          opacity: 0,
          ease: 'power1',
          duration: 2
        });
    }, quizStep2Answer);
    return () => ctx.revert();
  }, [width]);

  return (
    <div ref={quizStep2Answer} className={`${styles.quiz__answer} ${styles['answer--2']}`}>
      <div className={`layout ${styles.layout}`}>
        {sliderValue < 50 ? (
          <h1>We're sorry to hear that... At GEM, we're all about purpose and positive, enduring change</h1>
        ) : (
          <h1>We're a perfect match! GEM is all about purpose and positive, enduring change</h1>
        )}
        <div className={styles['slider-wrapper']}>
          <QuizSlider
            disabled={true}
            value={sliderValue}
            vertical={!(width > 768)}
          />
        </div>
        {ClientImpactData && (
          <div className={styles['quiz__client-impact__block']}>
            <h2>Explore our unrivalled experience and client impact:</h2>
            <QuizClientImpactList list={ClientImpactData} />
          </div>
        )}
        <div className={styles['button-wrapper']}>
          <Button
            color="blue"
            type="link"
            size="m"
            link={`/quiz/${nextStep}`}
          >
            Next question
          </Button>
        </div>
      </div>
    </div>
  )
}

export default QuizStep2Answer