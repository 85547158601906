import React from 'react';
import ReactDOM from 'react-dom/client';
import { MemoryRouter } from 'react-router-dom';

import App from './App';
import ScrollToTop from './hooks/ScrollToTop';
import { BrowserHistoryContextProvider as BrowserHistoryProvider } from './context/BrowserHistoryStateContextProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MemoryRouter>
    <BrowserHistoryProvider>
      <ScrollToTop />
      <App />
    </BrowserHistoryProvider>
  </MemoryRouter>
);