import { SwiperSlide } from 'swiper/react';

import { Button, SwiperSlider, WebpFallback } from '../..';
import { IconArrowRightBlue } from '../../../utils/svgs';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from './index.module.scss';
import buttonStyles from '../../Button/index.module.scss';

const QuizClientImpactList = ({ list }) => {
  const { width } = useWindowSize();
  
  return (
    <div className={styles['quiz__client-impact__list']}>
      {width > 768 ? (
        <>
          {list && list.map(({image, title, link}, index) => (
            <div className={styles['quiz__client-impact__item']} key={index}>
              <div className={styles.pic}>
                <WebpFallback 
                  webp={image.webp}
                  notWebp={image.notWebp}
                  alt={title}
                />
              </div>
              <div className={styles.content}>
                <h3>{title}</h3>
                <Button
                  type="a"
                  size="link"
                  link={link}
                  newWindow={true}
                >
                  <span>See case</span>
                  <span className={buttonStyles.icon}>
                    <IconArrowRightBlue />
                  </span>
                </Button>
              </div>
            </div>
          ))}
        </>
      ) : (
        <SwiperSlider>
          {list && list.map(({image, title, link}, index) => (
            <SwiperSlide key={index}>
              <div className={styles['quiz__client-impact__item']}>
                <div className={styles.pic}>
                  <WebpFallback 
                    webp={image.webp}
                    notWebp={image.notWebp}
                    alt={title}
                  />
                </div>
                <div className={styles.content}>
                  <h3>{title}</h3>
                  <Button
                    type="a"
                    size="link"
                    link={link}
                    newWindow={true}
                  >
                    <span>See case</span>
                    <span className={buttonStyles.icon}>
                      <IconArrowRightBlue />
                    </span>
                  </Button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </SwiperSlider>
      )}
      
    </div>
  )
}

export default QuizClientImpactList