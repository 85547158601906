import { useState, useRef } from 'react';
import { CircleSlider } from "react-circle-slider";

import styles from './index.module.scss';

const QuizSliderCircle = ({ disabled = false, initialValue = 50 }) => {
  const [sliderValue, setSliderValue] = useState(initialValue);
  const tooltip = useRef(null);

  return (
    <div className={styles['quiz__slider-circle'] + (disabled ? ` ${styles['slider-circle--disabled']}` : '')}>
      <div className={styles['quiz__slider-circle__text']}>
        <div className={styles.percent}>{sliderValue}%</div>
        <div className={styles.info}>is done by Women</div>
      </div>
      <div ref={tooltip} className={styles.tooltip}>Move the dot</div>
      <CircleSlider 
        circleWidth={25}
        progressWidth={25}
        knobRadius={26}
        circleColor="#fff"
        progressColor="#00A9F4"
        knobColor="#fff"
        shadow={false}
        size={335}
        value={sliderValue}
        disabled={disabled}
        onChange={(value) => {
          setSliderValue(value);
          if(!tooltip.current.classList.contains(styles.hidden)) tooltip.current.classList.add(styles.hidden)
        }}
      />
    </div>
  )
}

export default QuizSliderCircle