import { WebpFallback } from '../../';

import styles from './index.module.scss';

const QuizGemColleaguesCarouselItem = ({ item: { image, title, text } }) => {
  return (
    <div className={styles['colleagues-gem-slider__item']}>
      <div className={styles.pic}>
        <WebpFallback 
          webp={image.webp}
          notWebp={image.notWebp}
          alt={title}
        />
      </div>
      <div className={styles.content}>
        <h2>{title}</h2>
        {text}
      </div>
    </div>
  )
}

export default QuizGemColleaguesCarouselItem