import { useState } from 'react';

import { Button, WebpFallback } from '../../';
import { IconQuestions } from '../../../utils/svgs';
import { QuizRandomQuoteSeal, QuizRandomQuoteSealPng } from '../../../utils/images';
import { RandomQuotesData } from '../../../utils/data';

import styles from './index.module.scss';

function randomIntFromInterval(max, min = 0) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const QuizRandomQuote = ({ setPositive, setNegative }) => {
  const [setCount, setSetCount] = useState(0);

  const onSetRandomQuote = () => {
    const negId = randomIntFromInterval(RandomQuotesData.negative.length - 1);
    const posId = randomIntFromInterval(RandomQuotesData.positive.length - 1);
    setNegative(RandomQuotesData.negative[negId]);
    setPositive(RandomQuotesData.positive[posId]);

    setSetCount(prev => prev + 1);
  }

  return (
    <div className={styles['feedback-fields__seal']}>
      <div className={styles.tooltip}>{setCount === 0 ? 'Do you want a random quote?' : 'Do you want another random quote?'}</div>
      <div className={styles.buttons}>
        <Button 
          size="s" 
          color="blue-border" 
          onClick={onSetRandomQuote}
        >
          Yes
        </Button>
        <Button 
          size="s" 
          color="blue-border"
          onClick={() => {
            setNegative('');
            setPositive('');
          }}
        >
          No
        </Button>
      </div>
      <div className={styles['seal-pic']}>
        <IconQuestions className={styles['icon-questions']} />
        <WebpFallback 
          webp={QuizRandomQuoteSeal}
          notWebp={QuizRandomQuoteSealPng}
          alt="Do you want a random quote?"
        />
      </div>
    </div>
  )
}

export default QuizRandomQuote